import React, { useState, useContext, useEffect } from "react";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import Table from "@ingka/table";
import { TableHeader, TableBody } from "@ingka/table";

function SiteDetails(props) {
   const tabs = [
     <Tab key="tab-1" text={"Overview"} tabPanelId="tab_1" />,
     <Tab key="tab-2" text={"Units"} active tabPanelId="tab_2" />,
    
  ];
  
  
  const tabPanels = [
    <TabPanel key="tab-1" tabPanelId="tab_1">
      <div className="overviewWrapper" style={styles.overviewWrapper}>
        <div className="firstGrid">
          <label style={styles.label} className="label">
            <p style={styles.bold}>Country</p>
            <p>{props.site.country}</p>
          </label>
          <label style={styles.label} className="label">
            <p style={styles.bold}>State/Province</p>
            <p>{props.site.state}</p>
          </label>
          <label style={styles.label} className="label">
            <p style={styles.bold}>Address</p>
            <p>{props.site.address}</p>
          </label>
          <label style={styles.label} className="label">
            <p style={styles.bold}>Location name</p>
            <p>{props.site.locationName}</p>
          </label>
          <label style={styles.label} className="label">
            <p style={styles.bold}>Transaction type</p>
            <p>{props.site.transactionType}</p>
          </label>
          <label style={styles.label} className="label">
            <p style={styles.bold}>Country project responsible</p>
            <p>{props.site.countryProjectResponsible}</p>
          </label>
          <label style={styles.label} className="label">
            <p style={styles.bold}>Site type</p>
            <p>{props.site.siteType}</p>
          </label>
          <label style={styles.label} className="label">
            <p style={styles.bold}>Site progress</p>
            <p>{props.site.siteProgress}</p>
          </label>
          <label style={styles.label} className="label">
            <p style={styles.bold}>Site status details</p>
            <p>{props.site.siteStatusDetails}</p>
          </label>
        </div>
        <div className="SecondGrid">
          <label style={styles.label} className="label">
            <p style={styles.bold}>Site accessible date</p>
            <p>{props.site.siteAccessibleDate}</p>
          </label>
          <label style={styles.label} className="label">
            <p style={styles.bold}>Land size (m2)</p>
            <p>{props.site.landSize}</p>
          </label>
          <label style={styles.label} className="label">
            <p style={styles.bold}>Gross building area (m2)</p>
            <p>{props.site.grossBuildingArea}</p>
          </label>
          <label style={styles.label} className="label">
            <p style={styles.bold}>Real estate cluster responsible</p>
            <p>{props.site.realEstateClusterResponsible}</p>
          </label>
          <label style={styles.label} className="label">
            <p style={styles.bold}>Part of an approved MPP</p>
            <p>{props.site.partOfAnApprovedMPP}</p>
          </label>
          <label style={styles.label} className="label">
            <p style={styles.bold}>Part of a city plan</p>
            <p>{props.site.partOfACityPlan}</p>
          </label>
          <label style={styles.label} className="label">
            <p style={styles.bold}>Confidential</p>
            <p>{props.site.confidential}</p>
          </label>
        </div>
      </div>
    </TabPanel>,
    <TabPanel key="tab-2" tabPanelId="tab_2">
      {props.site && props.site.units && props.site.units.length > 0 && (
        <>
          <Table style={styles.table}>
            <thead class="">
              <tr>
                <th style={styles.padded}>Id</th>
                <th style={styles.padded}>Part of programme</th>
                <th style={styles.padded}>Project name</th>
              </tr>
            </thead>
            <tbody>
              {props.site.units.map((unit, index) => (
                <tr>
                  <td style={styles.padded}>{unit.id}</td>
                  <td style={styles.padded}>{unit.partOfProgramme}</td>
                  <td style={styles.padded}>{unit.projectName}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </TabPanel>,
  ];
  return (
    <>
      <Tabs tabs={tabs} tabPanels={tabPanels} defaultActiveTab="tab_1" />
    </>
  );
}

export default SiteDetails;

const styles = {
  overviewWrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  label: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    padding: "1vh",
    gap: "2vw",
    itemsAlign: "center",
    justifyContent: "center",
  },
  bold: {
    fontWeight: "bold",
  },
  table: {
    width: "100%",
    padding: "1vh",
  },
  padded: {
    padding:"2vh"
  }
 
};
