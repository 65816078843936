import React, {useState, useContext, useEffect} from 'react'
import RadioButton from "@ingka/radio-button";
import { useUnitContext } from '../../../contexts/UnitContext';

function UnitScope(props) {
  const {
    choosenUnit,
    
    setName,
    setChoosenName,
   
    setValue,
    units,
    unitIndex
    
  } = useUnitContext();

  const [specificUnit, setSpecificUnit]=useState(props.unit)


  useEffect(async () => {
    await setSpecificUnit(props.unit)
  }, [unitIndex])

  function handleName(e) {
    setChoosenName(e.target.value)
    saveChanges("projectName", e)
  }

 async function saveChanges(valueName, e) {
    let newUnit = Object.assign({}, specificUnit)
    newUnit[valueName] = e.target.value
    
    setSpecificUnit(newUnit)
   await props.emitData(newUnit)
   
  }

  return (
    <div className="scopeWrapper">
      {props.units.length > 0 && choosenUnit && props.unit && specificUnit ? (
        <>
          <label style={styles.label} className="label">
            <p>Project name * </p>

            <input
              type="text"
              value={specificUnit.projectName}
              style={styles.input}
              onChange={(e) => saveChanges("projectName", e)}
            />
          </label>
          <label style={styles.label} className="label">
            <p>Unit type *</p>

            <select
              style={styles.input}
              onChange={(e) => saveChanges("unitType", e)}
              value={specificUnit.unitType}
            >
              <option value="default">Choose option</option>
              <option
                value="Centres meeting place"
                selected={specificUnit.unitType === "Centres meeting place"}
              >
                Centres meeting place
              </option>
              <option value="test">test</option>
            </select>
          </label>
          <label style={styles.label} className="label">
            <p>Establishment type *</p>

            <select
              style={styles.input}
              onChange={(e) => saveChanges("establishmentType", e)}
              value={specificUnit.establishmentType}
            >
              <option value="default">Choose option</option>
              <option value="New">New</option>
              <option value="Exit">Exit</option>
            </select>
          </label>
          <label style={styles.label} className="label">
            <p>Business unit ID</p>

            <input
              type="number"
              style={styles.input}
              value={specificUnit.bussinessUnitId}
              onChange={(e) => saveChanges("bussinessUnitId", e)}
            />
          </label>
          <label style={styles.label} className="label">
            <p>Gross unit area (m2)</p>

            <input
              type="number"
              style={styles.input}
              value={specificUnit.grossUnitArea}
              onChange={(e) => saveChanges("grossUnitArea", e)}
            />
          </label>
          <label style={styles.label} className="label">
            <p>Project start date *</p>

            <input
              type="text"
              value={specificUnit.projectStartDate}
              style={styles.input}
              onChange={(e) => saveChanges("projectStartDate", e)}
            />
          </label>
          <label style={styles.label} className="label">
            <p>CFF design start date</p>

            <input
              type="text"
              style={styles.input}
              value={specificUnit.designStartDate}
              onChange={(e) => saveChanges("designStartDate", e)}
            />
          </label>
          <label style={styles.label} className="label">
            <p>Opening date *</p>

            <input
              type="text"
              style={styles.input}
              value={specificUnit.openingDate}
              onChange={(e) => saveChanges("openingDate", e)}
            />
          </label>
          <label style={styles.label} className="label">
            <p>Construction start date</p>

            <input
              type="text"
              style={styles.input}
              value={specificUnit.constructionStartDate}
              onChange={(e) => saveChanges("constructionStartDate", e)}
            />
          </label>
          <label style={styles.label} className="label">
            <p>First outbound date</p>

            <input
              type="text"
              value={specificUnit.firstOutboundDate}
              style={styles.input}
              onChange={(e) => saveChanges("firstOutboundDate", e)}
            />
          </label>
          <label style={styles.label} className="label">
            <p>Project closing date</p>

            <input
              type="text"
              style={styles.input}
              value={specificUnit.projectClosingDate}
              onChange={(e) => saveChanges("projectClosingDate", e)}
            />
          </label>
          <label style={styles.label} className="label">
            <p>Unifier ID (CMT#)</p>

            <input
              type="text"
              style={styles.input}
              value={specificUnit.unifierId}
              onChange={(e) => saveChanges("unifierId", e)}
            />
          </label>
          <label style={styles.label} className="label">
            <p>Part of programme</p>

            <div className="radioButtons" style={styles.radioButtons}>
              <RadioButton
                id="radio1"
                name="groupRadios1"
                label="No"
                value="ON"
                defaultChecked="true"
                onClick={(e) => setValue("partOfProgramme", false)}
              />
              <RadioButton
                id="radio2"
                name="groupRadios1"
                label="Yes"
                value="OFF"
                onClick={(e) => setValue("partOfProgramme", true)}
              />
            </div>
          </label>
        </>
      ) : null}
    </div>
  );
}

export default UnitScope

const styles = {
  label: {
    display: "grid",
    gridTemplateColumns: "30% 30% 30%",
    padding: "2vh",
    gap: "2vw",
    itemsAlign: "center",
    justifyContent: "center",
  },
  input: {
    border: "1px solid black",
    borderRadius: "5px",
    height: "70%",
    paddingLeft: "1vh",
  },
  radioButtons: {
    display: "flex",
    flexDirection: "row",
    gap: "2vw",
  },
 
};