import React, { useState, useEffect, useContext, useRef } from "react";

import Dropdown from "react-bootstrap/Dropdown";
import Checkbox from "@ingka/checkbox";
import { useColumnContext } from "../../contexts/ColumnContext";
import DropdownItem from "react-bootstrap/esm/DropdownItem";


import Button from "@ingka/button";
import Pill from "@ingka/pill";
import chevronDown from "@ingka/ssr-icon/paths/chevron-down";

import { Select, Option, OptGroup } from "@ingka/select";


function ColumnSelecter() {
  const {
    columns,
    userColumns,

    addNewColumn,
    removeColumn,
    resetColumns,
   
  } = useColumnContext();


  async function changeValue(key) {

   
   
    if (userColumns[key]) {
      await removeColumn(key);
     
    }
    else {
      await addNewColumn(key);
      
    }

 
    
    
    
  
  }
  const objectList = Object.keys(columns).map((key) => (
    <div className="itemWrapper" style={styles.itemWrapper}>
      {userColumns && (
        <>
          <DropdownItem>
            {key
              .split(/(?=[A-Z])/)
              .join(" ")
              .toLowerCase()}
              
          </DropdownItem>

          <Checkbox checked={userColumns[key]} onChange={(e) => changeValue(key)} />
          
        </>
      )}
    </div>
  ));
  

  const [open, setOpen] = useState(false)
  const drop = useRef(null);

  const liCls =
  "p-3 border text-gray-700 hover:text-white hover:bg-indigo-700 cursor-pointer";
const SortButton = ({ onClick }) => (

  <Pill label="Sort" ssrIcon={chevronDown} iconPosition="trailing" onClick={onClick}/>
);

const DropDownCard = ({ data = [] }) => (
  <div className="shadow h-auto w-56 absolute" style={{ position: "relative" }}>
    <div className="text-left">
      {data.map((item, i) => (
        <div key={i} className={liCls} style={{ textAlign: "left" }}>
          {item}
          </div>
      ))}
    <Button type="secondary" text="Reset columns" small="true" style={styles.btn} onClick={async e=> await resetColumns()}/>
    </div>
  </div>
);

  return (
    <>
      {/* <Dropdown> */}
        {/* <Dropdown.Toggle className="columnDropDownBtn" id="dropdown-basic">
          Sort
        </Dropdown.Toggle> */}
 
        <div className="dropdown"
      ref={drop}
      style={{
        position: "relative",
        width: "auto",
        display: "block",
        zIndex: 1000,
        
      }}>

<Pill label="Sort" ssrIcon={chevronDown} iconPosition="trailing"  onClick={() => setOpen(open => !open)}/>

        {/* <SortButton label="Sort" ssrIcon={chevronDown} iconPosition="trailing" onClick={() => setOpen(open => !open)} /> */}

        
        {open && <div style={{marginTop: "10px", overflowY: "scroll", maxHeight: "25vw", position: "absolute"}}> <DropDownCard data={objectList} /> </div>  }
        
        {/* <Button type="secondary" text="Reset columns" small="true" style={styles.btn} onClick={async e=> await resetColumns()}/> */}
          
        </div>


        {/* <Dropdown.Menu className="columnMenu">
          {columns &&  userColumns &&<>{objectList}</>}
          <Button type="secondary" text="Reset columns" small="true" style={styles.btn} onClick={async e=> await resetColumns()}/>
        </Dropdown.Menu>
      </Dropdown> */}
    </>
  );
}

export default ColumnSelecter;

const styles = {
  itemWrapper: {
    display: "flex",
    flexDirection: "row",
    paddingRight: "2vw",
  },
  btn: {
    marginTop:"3vh",

 
  }
 
};
