import React, { useState, useEffect, useContext } from "react";
import Button from "@ingka/button";
import { useDecisionPointsContext } from "../../contexts/DecisionPointsContext";
import Select, { Option, OptGroup } from "@ingka/select";
import { tableBodyClasses } from "@mui/material";

function DecisionPointsInputs(props) {
  const {
    projectDecisionPoints,
    fetchDecisionPointInput,
    pointsToEdit,
    setPointsToEdit,
    currentDP,
    setCurrentDP,
  } = useDecisionPointsContext();
  const [decisionPointInputs, setDecisionPointInputs] = useState({});
  const [editing, setEditing] = useState(false);
  const [DPObject, setDPObject] = useState({});
  const arr = [];
  const [objectToEdit, setObjectToEdit] = useState({});

  useEffect(async () => {
    let res = await fetchDecisionPointInput(props.dp);
    setDecisionPointInputs(res);
    setDPObject(props.dpObject);
  }, []);

  const editDPs = async (value, i, dpName) => {
    //Copying object to set its value on select
    let copy = Object.assign(DPObject, {});
    copy[i] = value;
    setDPObject({ ...copy });

    objectToEdit[dpName] = { ...objectToEdit[dpName], [i]: value };

    setPointsToEdit({ ...pointsToEdit, ...objectToEdit });
  };

  const inputs = () => {
    let amountOfFields = 0;

    Object.keys(decisionPointInputs).map((key) => {
      if (key.includes("field")) {
        amountOfFields++;
      }
    });

    const disableInput = () => {
     
      
      const propsNumber = Number(props.dp.split("P")[1])
      const numberIsProgress = Number(currentDP.split("P")[1])
      if (propsNumber <= numberIsProgress) {
        return false
      }

      return true
     
    }


    for (let i = 1; i <= amountOfFields; i++) {
      arr.push(
        <div className="dpItem" style={styles.dpItem}>
          <label style={styles.label}>
            <p style={styles.margin}>{decisionPointInputs["field" + i]}</p>

            <Select
              label=""
              className="selcetField"
              hintText="Review/Approval status"
              onChange={(e) => editDPs(e.target.value, i, props.dp)}
              value={DPObject[i]}
              disabled={disableInput()}
            >
              {decisionPointInputs["choices" + i].map((choice) => (
                <Option value={choice} name={choice} />
              ))}
            </Select>

            <input
              type="text"
              style={styles.input}
              placeholder="Review/Approval date"
              disabled={disableInput()}
            />
          </label>
          {disableInput()}
        </div>
      );
    }

    return arr;
  };

  return (
    <div className="decisionPointInfo" style={styles.decisionPointInfo}>
      {decisionPointInputs && <>{inputs()}</>}
    </div>
  );
}

export default DecisionPointsInputs;

const styles = {
  label: {
    display: "grid",
    gridTemplateColumns: "50% 30% 20%",
    gridTemplateRows: "auto",
    gap: "2vw",
  },
  decisionPointInfo: {
    width: "150%",
    marginTop: "5vh",
  },
  dpItem: {
    marginTop: "5vh",
  },

  input: {
    border: "1px solid grey",
    paddingLeft: "1vw",
    borderRadius: "5px",
  },
};
