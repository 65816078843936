import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Dropdown from "react-bootstrap/Dropdown";
import arrowDown from "@ingka/ssr-icon/paths/arrow-down";
import SSRIcon from "@ingka/ssr-icon";
import styled from "styled-components";

import { RiFilterOffFill } from "react-icons/ri";
import { useProjectContext } from "../contexts/ProjectContext";

import Search from "@ingka/search";
import Select, { Option, OptGroup } from "@ingka/select";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Button from "@ingka/button";
import Accordion, { AccordionItem } from "@ingka/accordion";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/accordion/dist/style.css";
import Pill from "@ingka/pill";

const Container = styled.div`
  display: grid;
  height: 100vh;

  color: white;

  grid-template-rows: 0.2fr 1fr 0.5fr 0.5fr;

  text-align: center;
  grid-gap: 0.25rem;

  position: relative;
`;
const ContentBox = styled.div`
  display: row;
  gap: 1rem;
  padding: 0.55rem;
  align-items: center;
  grid-area: content;
  justify-content: center;
`;

const Content1 = styled.div`
  padding: 1rem;
  width: 100%;
  height: 100%;
`;

const Footer = styled.footer`
  grid-area: footer;

  padding: 1rem;
`;

const Content2 = styled(Content1)``;
const Content3 = styled(Content1)``;
const Content4 = styled(Content1)``;
const Content5 = styled(Content1)``;

export default function DropdownMenu() {
  const [countries, setCountries] = useState([]);
  const {
    setMainProject,
    setCity,
    setCountry,
    fetchExistingCountries,
    country,
    city,
    mainProject,
  } = useProjectContext();

  const [value, onChange] = useState(new Date());

  async function onSearch(e, data) {
    console.log(data.value);
    setCity(data.value);
  }

  async function onSelect(e, data) {
    console.log(data.value);
    setMainProject(data.value);
  }

  const resetOnSelect = (e) => {
    e.preventDefault();
    setMainProject("");
    setCity("");
    setCountry("all");
  };

  async function changeCountry(e) {
    console.log(e.target.value);
    if (e.target.value === "All") {
      setCountry("all");
    } else {
      setCountry(countries[e.target.value]);
    }
  }

  // const resetCountryList = () => {
  //   setCountry("all");
  // }

  useEffect(() => {
    async function fetchCountries() {
      const response = await fetchExistingCountries();
      console.log("countries are ", response);
      setCountries(response);
    }
    fetchCountries();
  }, []);

  return (
    <Container>
      <div className="dropWrapper" style={styles.dropWrapper}>
        <ContentBox>
          <Content1 style={styles.search}>
            {/* <div className="projectName">
              <Dropdown>
                <Dropdown.Toggle style={styles.dropdownToggle}>
                  Project Name
                </Dropdown.Toggle>
                <Dropdown.Menu>
                <Dropdown.Item href="#">
                    A <SSRIcon paths={arrowDown} /> A överst
                    <br />Ö
                    </Dropdown.Item>

                  <Dropdown.Item href="#">
                  Ö <SSRIcon paths={arrowDown} /> Ö överst
                  <br />A
                  </Dropdown.Item>
                  <hr />
                  <Dropdown.Item href="#">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                    <RiFilterOffFill />
                    &nbsp;<span>Ta bort alla filter från Project Name</span>
                    </div>
                    </Dropdown.Item>
                    </Dropdown.Menu>
                    </Dropdown>
                  </div> */}

            <Accordion>
              <AccordionItem
                title={"Project name"}
                open={false}
                caption={mainProject}
              >
                <div className="projectName">
                  <Search id="search" onChange={onSelect} value={mainProject} />
                </div>
              </AccordionItem>
            </Accordion>
          </Content1>

          <Content2>
            <Accordion>
              <AccordionItem title={"Country"} open={false} caption={country}>
                <div className="country">
                  {/* <Dropdown>
                <Dropdown.Toggle style={styles.dropdownToggle}>
                Country
              </Dropdown.Toggle> */}

                  <div
                    style={{ border: "0.5px solid white", borderRadius: "5px" }}
                  >
                    <Select
                      hintText="All"
                      label={false}
                      value={countries.indexOf(country)}
                      onChange={(e) => changeCountry(e)}
                    >
                      {countries && countries.length > 0 ? (
                        <>
                          {countries.map((data, key) => {
                            return (
                              <Option
                                style={{
                                  background: "transparent",
                                  color: "black",
                                }}
                                value={key}
                                name={data}
                              />
                            );
                          })}
                        </>
                      ) : (
                        <p>All</p>
                      )}
                    </Select>
                  </div>

                  {/* </Dropdown> */}
                </div>
              </AccordionItem>
            </Accordion>
          </Content2>

          <Content3 style={styles.search}>
            <Accordion>
              <AccordionItem title={"City"} open={false} caption={city}>
                <div className="city">
                  <p style={{ fontSize: "20px", color: "white" }}>City</p>

                  <Search
                    id="search"
                    onChange={onSearch}
                    value={city === "all" ? "" : city}
                  />
                </div>
              </AccordionItem>
            </Accordion>
          </Content3>

          {/* <Content4>
          <Accordion>
          <AccordionItem title={"Location name"} open={true} >
            <div className="locationName">
              <Dropdown>
                <Dropdown.Toggle style={styles.dropdownToggle}>
                  Location Name
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#">
                    A <SSRIcon paths={arrowDown} /> Stigande
                    <br />Ö
                  </Dropdown.Item>

                  <Dropdown.Item href="#">
                    Ö <SSRIcon paths={arrowDown} /> Fallande
                    <br />A
                  </Dropdown.Item>
                  <hr />
                  <Dropdown.Item href="#">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <RiFilterOffFill />
                      &nbsp;<span>Ta bort alla filter från Location Name</span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            </AccordionItem>
            </Accordion>
          </Content4>

          <Content5>
            <div className="openingDate">
              <Dropdown>
                <Dropdown.Toggle style={styles.dropdownToggle}>
                  Opening Date
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div>
                    <Calendar onChange={onChange} value={value} />
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Content5> */}
        </ContentBox>
      </div>
    </Container>
  );
}

const styles = {
  dropdownToggle: {
    backgroundColor: "transparent",
    fontSize: "20px",
    borderColor: "white",
    color: "white",
    width: "100%",
  },

  dropWrapper: {
    width: "100%",
    tableLayout: "fixed",
    borderCollapse: "collapse",
  },

  search: {
    color: "black",
  },

  countryDropdown: {
    borderRadius: "5px",
    backgroundColor: "transparent",
    color: "black",
  },
};
