import React, {useContext, useEffect, useState} from 'react'
import Button from "@ingka/button";
import { Countries } from '../../../countries';
import Select, { Option, OptGroup } from "@ingka/select";
import { useProjectContext } from '../../../contexts/ProjectContext';
import {useSiteContext} from '../../../contexts/SiteContext'

function Mandatory(props) {

  
  const { siteToPost, setSiteToPost } = useSiteContext();
  

  
  






  return (
    <div className="formWrapper" style={styles.formWrapper}>
      <form className="form" style={styles.form}>
        <div className="inputs">
          <label style={styles.label} className="label">
            <p>Country</p>

            <select
              style={styles.input}
              required
              value={siteToPost.country}
              onChange={(e) =>
                setSiteToPost({
                  ...siteToPost,
                  country: e.target.value,
                })
              }
            >
              {Countries.map((data, key) => {
                return <option value={data.name}>{data.name} </option>;
              })}
            </select>
          </label>
          <label style={styles.label} className="label">
            <p>City</p>

            <input
              type="text"
              style={styles.input}
              value={siteToPost.city}
              required
              onChange={(e) =>
                setSiteToPost({ ...siteToPost, city: e.target.value })
              }
            />
          </label>
          <label style={styles.label} className="label">
            <p>Transaction type</p>

            <select
              style={styles.input}
              required
              value={siteToPost.transactionType}
              onChange={(e) =>
                setSiteToPost({
                  ...siteToPost,
                  transactionType: e.target.value,
                })
              }
            >
              <option value="Buy">Buy</option>
              <option value="Cancel lease">Cancel lease</option>
              <option value="Divest">Divest</option>
              <option value="Lease">Lease</option>
              <option value="No transaction">No transaction</option>
            </select>
          </label>
          <label style={styles.label} className="label">
            <p>Country project responsible </p>

            <input
              type="text"
              style={styles.input}
              value={siteToPost.countryProjectResponsible}
              required
              onChange={(e) =>
                setSiteToPost({
                  ...siteToPost,
                  countryProjectResponsible: e.target.value,
                })
              }
            />
          </label>
        </div>
      </form>
    </div>
  );
}

export default Mandatory

const styles = {
  btnWrapper: {
    padding: "4vw 4vw 0 0",
    display: "grid",
    justifyItems: "end",
    cursor: "default",
  },
  input: {
    border: "1px solid black",
    borderRadius: "5px",
    height: "70%",
    paddingLeft: "1vh",
  },
  label: {
    display: "grid",
    gridTemplateColumns: "30% 40%",
    padding: "2vh",
    gap: "2vw",
    itemsAlign: "center",
    justifyContent: "center",
  },
  btn: {
    color: "white",
    backgroundColor: "#0058a3",
    padding: "2vh 4vh 2vh 4vh",
    borderRadius: "25px",
    cursor: "pointer",
  },
};