import React, { useState, useEffect, useContext } from 'react'
import Pagination from "react-bootstrap/Pagination";
import { useProjectContext } from '../contexts/ProjectContext'

function Pages(props) {
  const { currentPage, setCurrentPage, maxPage, country, currentProjects, setLastIndex } = useProjectContext()
  
  function handlePrev() {
    if (currentPage - 1 !== 0) {
      /*   if (country !== "all") {
          setLastIndex(currentProjects[currentProjects.length - 1].project_ID-5);
        } */
      setCurrentPage(currentPage-1)
    }
  }

  function handleNext() {
    if (!(currentPage + 1 > maxPage)) {
     /*  if (country !== "all") {
        setLastIndex(currentProjects[currentProjects.length-1].project_ID)
      } */
      setCurrentPage(currentPage+1)
    }
  }
  return (
    <Pagination>
      <Pagination.First onClick={(e) => setCurrentPage(1)} />
      <Pagination.Prev onClick={(e) => handlePrev()} />
      <div className="wrapper" style={styles.wrapper}>
        <Pagination.Item>
          {currentPage} out of {maxPage}
        </Pagination.Item>
        
      </div>

      <Pagination.Next onClick={(e) => handleNext()} />
      <Pagination.Last onClick={(e) => setCurrentPage(maxPage)} />
    </Pagination>
  );
}

export default Pages

const styles = {
  wrapper: {
    display: "flex",
    flexDirection:"row",
    padding:"0 2vw 0 2vw",
    textAlign: "center",
    
  },
 
}