import React from 'react'

function UnitFinancials(props) {
  return (
    <div className="wrapper">
      {props.units.length > 0 ? (
        <>
          <label style={styles.label} className="label">
            <p>Total project investment</p>

            <input type="text" style={styles.input} />
          </label>
          <label style={styles.label} className="label">
            <p>Project background and scope (facts & bullet points)</p>

            <textarea
              style={styles.textArea}
              placeholder="Main reason for the project 
Scope 
Store component and area involved 
Equipment involved
            "
              rows="7"
            />
          </label>
          <label style={styles.label} className="label">
            <p>Solution description and cost</p>

            <textarea
              style={styles.textArea}
              placeholder="Area in m2 
Type of equipment and qty 
Main equipment costs 
Resources needs and staff costs"
              rows="7"
            />
          </label>
          <label style={styles.label} className="label">
            <p>Estimated return or if no return please comment</p>

            <textarea
              style={styles.textArea}
              placeholder="Expected return type, E.G. Additionnal sales of,
Operationnal Savings of, Cost savings of... 
Payback time, E.G. 24 months 
if no payback, what is the risk of not doing the work."
              rows="7"
            />
          </label>
        </>
      ) : null}
    </div>
  );
}

export default UnitFinancials

const styles = {
  label: {
    display: "grid",
    gridTemplateColumns: "30% 30% 30%",
    padding: "2vh",
    gap: "2vw",
    itemsAlign: "center",
    justifyContent: "center",
  },
  input: {
    border: "1px solid black",
    borderRadius: "5px",
    height: "70%",
    paddingLeft: "1vh",
  },
  textArea: {
    minHeight: "15vh",
    minWidth:"30vw",
     border: "1px solid black",
    borderRadius: "5px",
    padding:"1vh"
  }
};