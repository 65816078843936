import React, { useState, useEffect, useContext } from "react";
import { useSiteContext } from "../../../contexts/SiteContext";
import SSRIcon from "@ingka/ssr-icon";
import trash from "@ingka/ssr-icon/paths/trash-can";
import Select, { Option, OptGroup } from "@ingka/select";
import documentAdd from "@ingka/ssr-icon/paths/document-add";
import BatchInputValidation from "./BatchInputValidation";
import { usePostProjectContext } from "../../../contexts/PostProjectContext";

function BatchInputList() {
  const { siteToPost } = useSiteContext()
  const [editArr, setEditArr] = useState(["Choose an option"]);
  const [newFieldNumber, setNewFieldNumber] = useState(0)
  const { editObject, setEditObject } = usePostProjectContext();
 
  
  async function changeValue(index, fieldName) {

  console.log("index is" , index, "and field name ", fieldName)
    let newObject = Object.assign(editObject, {})
      let oldKey=Object.keys(newObject)[index]
    Object.defineProperty(newObject,fieldName,Object.getOwnPropertyDescriptor(newObject, oldKey));
    delete newObject[oldKey];
  await  setEditObject({...newObject})
    console.log(newObject)
  }

  async function addNewField() {
    let newObject = Object.assign(editObject, {});
    newObject.chooseOption = ""
   await setEditObject({ ...newObject })
    console.log(newObject)
  }

  async function deleteField(key) {
    
    let newObject = Object.assign(editObject, {});
     delete newObject[key]
     await setEditObject({...newObject})
  }


  return (
    <>
      {editObject && (<div className="inputList" style={styles.inputList}>
      <p style={{ fontWeight: "bold" }}>Choose fields to edit</p>
      {Object.keys(editObject).map((key, index) => (
        <div className="inputItem" style={styles.inputItem}>
          <Select
            label=""
            value={key}
            className="selcetField"
            onChange={(e) => changeValue(index, e.target.value)}
          >
            {Object.entries(siteToPost).map(([key, value], i) => (
              <Option
                value={key}
                name={key
                  .split(/(?=[A-Z])/)
                  .join(" ")
                  .toLowerCase()}
              />
            ))}
          </Select>
          <div style={styles.trash} className="trash">
            {/*      <input type="text" style={styles.input} className="fieldInput" /> */}
            <BatchInputValidation field={key} />
             <SSRIcon paths={trash} style={styles.trashIcon}  onClick={e=> deleteField(key)}/>
          </div>
        </div>
      ))}
      <div className="addField" style={styles.addField}>
        <p style={{ marginTop: "2vh" }}>Add new field</p>
        <SSRIcon
          paths={documentAdd}
          style={styles.trashIcon}
          onClick={(e) => addNewField()}
        />
      </div>
      </div>)}
    
      </>
  );
}

export default BatchInputList;

const styles = {
  inputItem: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    marginTop: "2vh",
    gap: "4vw",
    alignItems: "center",
    marginTop:"5vh"
  },

  inputList: {
    width: "90%",
  },
  input: {
    border: "1px solid black",
    borderRadius: "5px",

    paddingLeft: "1vh",
  },
  trash: {
    display: "grid",
    gridTemplateColumns: "70% 30%",
    gap: "1vw",
    height: "100%",
    alignItems: "end",
  },
  trashIcon: {
    alignSelf: "center",
    color: "dark-grey",
    cursor: "pointer",
  },
  addField: {
    display: "flex",
    alignItems: "stretch",
    marginTop: "4vh",
    gap: "1vw",
    
  }
};
