import React, { useEffect, useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "@ingka/button";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import { useSiteContext } from "../../contexts/SiteContext"
import Mandatory from "../CreateNewProject/Site/SiteMandatory"
import SiteReview from "../CreateNewProject/Site/SiteReview"
import SiteAdditionalInfo from "../CreateNewProject/Site/SiteAdditionalInfo";
import { Countries } from "../../countries";
import { useUnitContext } from "../../contexts/UnitContext";
import EditConnections from "./EditConnections";
import { usePostProjectContext } from "../../contexts/PostProjectContext";

function EditProjectModal(props) {
  const {
    siteToPost,
    setSiteToPost,
    resetSiteValues,
    siteId,
    setSiteId,
    deleteUnitFromSite,
  } = useSiteContext();
  const {editProject}=usePostProjectContext()
  const {
    units,
    setUnits,
    unitSize,
    setUnitSize,
    setUnitIdsToDelete,
    oldUnitIds,
    setOldUnitIds,
    unitsToAdd,
    setUnitsToAdd,
    unitIdsToDelete,
  } = useUnitContext();
  
    const siteTabs = [
      <Tab key="tab-1" text={"Overview"} tabPanelId="tab_1" />,
      <Tab key="tab-2" text={"Review & status"} tabPanelId="tab_2" />,
      <Tab key="tab-3" text={"Connections"} tabPanelId="tab_3" />,
    ];

    const siteTabPanels = [
      <TabPanel key="tab-1" tabPanelId="tab_1">
        <>
          {" "}
          <div className="mandatoryWrapper" style={styles.wrapper}>
            <div>
              <label style={styles.label} className="label">
                <p>Country</p>

                <select
                  style={styles.input}
                  required
                  value={siteToPost.country}
                  onChange={(e) =>
                    setSiteToPost({
                      ...siteToPost,
                      country: e.target.value,
                    })
                  }
                >
                  {Countries.map((data, key) => {
                    return <option value={data.name}>{data.name} </option>;
                  })}
                </select>
              </label>
              <label style={styles.label} className="label">
                <p>City</p>

                <input
                  type="text"
                  style={styles.input}
                  value={siteToPost.city}
                  required
                  onChange={(e) =>
                    setSiteToPost({ ...siteToPost, city: e.target.value })
                  }
                />
              </label>
            </div>
            <div>
              <label style={styles.label} className="label">
                <p>Transaction type</p>

                <select
                  style={styles.input}
                  required
                  value={siteToPost.transactionType}
                  onChange={(e) =>
                    setSiteToPost({
                      ...siteToPost,
                      transactionType: e.target.value,
                    })
                  }
                >
                  <option value="Buy">Buy</option>
                  <option value="Cancel lease">Cancel lease</option>
                  <option value="Divest">Divest</option>
                  <option value="Lease">Lease</option>
                  <option value="No transaction">No transaction</option>
                </select>
              </label>
              <label style={styles.label} className="label">
                <p>Country project responsible </p>

                <input
                  type="text"
                  style={styles.input}
                  value={siteToPost.countryProjectResponsible}
                  required
                  onChange={(e) =>
                    setSiteToPost({
                      ...siteToPost,
                      countryProjectResponsible: e.target.value,
                    })
                  }
                />
              </label>
            </div>
          </div>
          <SiteAdditionalInfo />
          <div style={styles.wrapper}>
            <div></div>
            <label style={styles.label} className="label">
              <p>Main project</p>
              {units && units.length > 0 && (
                <select
                  style={styles.input}
                  required
                  value={siteToPost.mainProject}
                  onChange={(e) =>
                    setSiteToPost({
                      ...siteToPost,
                      mainProject: e.target.value,
                    })
                  }
                >
                  {props.project.site.units.map((data, key) => {
                    return (
                      <option value={data.projectName}>
                        {data.projectName}{" "}
                      </option>
                    );
                  })}
                </select>
              )}
            </label>
          </div>
        </>{" "}
      </TabPanel>,

      <TabPanel key="tab-2" tabPanelId="tab_2">
        <SiteReview />
      </TabPanel>,
      <TabPanel key="tab-3" tabPanelId="tab_3">
        <EditConnections refresh={saveChanges} />
      </TabPanel>,
    ];
  
  async function saveChanges() {
   
    //Deleting units that user deleted
    if (unitIdsToDelete.length > 0) {
      await deleteUnitFromSite()
      setUnitIdsToDelete([])
   }
    
  //Adding and updating new units to the site
    
    //Changing site field values
    let res = await editProject(siteId, props.project.id)
    if (res.status === 200) {
       setSiteId(0)
    await resetSiteValues()
      props.closeModal()
      props.editSuccess(true)
    }
    else {
      props.editSuccess(false);
    }
   

  }
  async function closeModal() {
     await resetSiteValues();
    props.closeModal()
  
  }

  useEffect(async () => {
    let currentSite = props.project.site
    const { units, id, ...newSite } = currentSite;
    setSiteId(id)
   
    
    await setUnitSize(units.length);
    await setUnits(units);
    
     await setSiteToPost(newSite)
   }, []);
  return (
    <>
      {siteToPost && (
        <Modal
          show={props.show}
          onHide={(e) => closeModal()}
          dialogClassName="my-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Editing project #{props.project.id}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs
              tabs={siteTabs}
              tabPanels={siteTabPanels}
              defaultActiveTab="tab_1"
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="footer" style={styles.footer}>
              <Button
                type="emphasised"
                className="emphasised"
                small="true"
                text="Delete project"
              />
              <Button
                type="emphasised"
                className="emphasised"
                small="true"
                text="Save changes"
                onClick={e=> saveChanges()}
              />
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default EditProjectModal;

const styles = {
  footer: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "70%",
  },
  wrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "3vw",
  },
  label: {
    display: "grid",
    gridTemplateColumns: "40% 50%",
    padding: "2vh",
    gap: "2vw",
    itemsAlign: "center",
    justifyContent: "center",
  },
  input: {
    border: "1px solid black",
    borderRadius: "5px",
    height: "70%",
    paddingLeft: "1vh",
  },
};