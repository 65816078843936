import React from "react";

function SiteReview(props) {
  function handleSubmit(e) {
    e.preventDefault();
    props.emitData("tab_3");
  }
  return (
    <div className="formWrapper">
      <form
        onSubmit={(e) => handleSubmit(e)}
        className="form"
        style={styles.form}
      >
        <div className="inputs">
          <label style={styles.label} className="label">
            <p>Site IR reviewed by PIRC</p>

            <input
              type="text"
              style={styles.input}
              placeholder="Review/approval date"
            />

            <select style={styles.input}>
              <option value="test">Review/approval status</option>
            </select>
          </label>
          <label style={styles.label} className="label">
            <p>Site IR reviewed by SGDI</p>

            <input
              type="text"
              style={styles.input}
              placeholder="Review/approval date"
            />

            <select style={styles.input}>
              <option value="test">Review/approval status</option>
            </select>
          </label>
          <label style={styles.label} className="label">
            <p>Site IR submission approved by PSG</p>

            <input
              type="text"
              style={styles.input}
              placeholder="Review/approval date"
            />

            <select style={styles.input}>
              <option value="test">Review/approval status</option>
            </select>
          </label>
          <label style={styles.label} className="label">
            <p>Site IR approved by Country Board</p>

            <input
              type="text"
              style={styles.input}
              placeholder="Review/approval date"
            />

            <select style={styles.input}>
              <option value="test">Review/approval status</option>
            </select>
          </label>
          <label style={styles.label} className="label">
            <p>Site IR approved by Real Estate & Expansion Council</p>

            <input
              type="text"
              style={styles.input}
              placeholder="Review/approval date"
            />

            <select style={styles.input}>
              <option value="test">Review/approval status</option>
            </select>
          </label>
          <label style={styles.label} className="label">
            <p>Site IR approved by Investment Committee</p>

            <input
              type="text"
              style={styles.input}
              placeholder="Review/approval date"
            />

            <select style={styles.input}>
              <option value="test">Review/approval status</option>
            </select>
          </label>
          <label style={styles.label} className="label">
            <p>Site IR approved by Supervisory Board</p>

            <input
              type="text"
              style={styles.input}
              placeholder="Review/approval date"
            />

            <select style={styles.input}>
              <option value="test">Review/approval status</option>
            </select>
          </label>
        </div>

       
      </form>
    </div>
  );
}

export default SiteReview;

const styles = {
  btnWrapper: {
    padding: "4vw 4vw 0 0",
    display: "grid",
    justifyItems: "end",
    marginTop: "3vh",
    cursor: "default",
  },
  btn: {
    color: "white",
    backgroundColor: "#0058a3",
    padding: "2vh 4vh 2vh 4vh",
    borderRadius: "25px",
    cursor: "pointer",
  },
  label: {
    display: "grid",
    gridTemplateColumns: "30% 30% 30%",
    padding: "2vh",
    gap: "2vw",
    itemsAlign: "center",
    justifyContent: "center",
  },
  input: {
    border: "1px solid black",
    borderRadius: "5px",
    height: "70%",
    paddingLeft: "1vh",
  },
};
