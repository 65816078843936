import React, { useState, useEffect, useContext } from "react";
import Modal, { Sheets, ModalHeader, ModalFooter, ModalBody } from "@ingka/modal";
import Accordion, { AccordionItem } from "@ingka/accordion";
import { useSiteContext } from "../contexts/SiteContext";
import { useProjectContext } from "../contexts/ProjectContext";
import Select, { Option, OptGroup } from "@ingka/select";
import { useUnitContext } from "../contexts/UnitContext";
import InputField from "@ingka/input-field";
import Button from "@ingka/button";

function FilterSidebar(props) {
  const { setCountry, setCity, setLocationName, city, locationName, country } = useSiteContext()
  const {projectName, setProjectName, grossUnitArea, setGrossUnitArea, establishmentType, setEstablishmentType}=useUnitContext()
  const { fetchExistingCountries, existingCountries } = useProjectContext();
  useEffect(async () => {
    await fetchExistingCountries()
  }, [])
  
  async function clearFilters() {
    if (props.entity === "site") {
      await setCountry("all")
      await setCity("")
      await setLocationName("")
    }
    if (props.entity === "unit") {
      await setEstablishmentType("all")
      await setGrossUnitArea(0)
      await setProjectName("")
    }
  }
  return (
    <Modal
      escapable="true"
      handleCloseBtn={(e) => props.closeModal()}
      visible={props.openModal}
      style={{zIndex: "2000"}}
    >
      <Sheets
        className="sheets"
       
        header={<ModalHeader title="Filter and sort" />}
        footer={
          <ModalFooter
            children={
              <Button
                type="primary"
                text="Clear filters"
                small={true}
                style={{ fontWeight: "bold", color: "white" }}
                onClick={(e) => clearFilters()}
              />
            }
          />
        }
      >
        <ModalBody>
          {props.entity === "site" && (
            <Accordion>
              {existingCountries && (
                <>
                  <AccordionItem title={"Country"} id="1">
                    <Select
                      label=""
                      hintText=""
                      className="selcetField"
                      onChange={(e) => setCountry(e.target.value)}
                      value={country}
                    >
                      <Option value="all" name="All" />;
                      {existingCountries.map((data, key) => {
                        return <Option value={data} name={data} />;
                      })}
                    </Select>
                  </AccordionItem>
                  <AccordionItem title={"City"} id="2">
                    <InputField
                      label="City"
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                    />
                  </AccordionItem>
                  <AccordionItem title={"Location name"} id="3">
                    <InputField
                      label="Location name"
                      value={locationName}
                      onChange={(e) => {
                        setLocationName(e.target.value);
                      }}
                    />
                  </AccordionItem>
                </>
              )}
            </Accordion>
          )}

          {props.entity === "unit" && (
            <Accordion>
              <AccordionItem title={"Establishment type"} id="1">
                <Select
                  label=""
                  hintText=""
                  className="selcetField"
                  onChange={(e) => setEstablishmentType(e.target.value)}
                  value={establishmentType}
                >
                  <Option value="all" name="All" />
                  <Option value="New" name="New" />
                  <Option value="Divest" name="Divest" />
                  <Option value="Exit" name="Exit" />
                  <Option value="Close" name="Close" />
                  <Option value="SISAN-Rebuild" name="SISAN-Rebuild" />
                  <Option value="SISAN-Remodel" name="SISAN-Remodel" />
                  <Option value="Retrofit" name="Retrofit" />
                  <Option value="Relocation" name="Relocation" />
                </Select>
              </AccordionItem>

              <AccordionItem title={"Project name"} id="2">
                <InputField
                  label="Project name"
                  value={projectName}
                  onChange={(e) => {
                    setProjectName(e.target.value);
                  }}
                />
              </AccordionItem>
              <AccordionItem title={"Gross unit area (m2)"} id="3">
                <InputField
                  label="Gross unit area (m2)"
                  value={grossUnitArea}
                  inputMode="numeric"
                  type="number"
                  onChange={(e) => {
                    setGrossUnitArea(Number(e.target.value));
                  }}
                />
              </AccordionItem>
            </Accordion>
          )}
        </ModalBody>
      </Sheets>
    </Modal>
  );
}

export default FilterSidebar;

const styles = {
 
}
