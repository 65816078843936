import React, { createContext, useContext, useState, useEffect } from "react";
import { useSiteContext } from "./SiteContext";
import { useUnitContext } from "./UnitContext";
import { useUserContext } from "./UserContext";
import { useNavigate } from "react-router-dom";
import { useProjectContext } from "./ProjectContext";

const PostProjectContext = createContext("");

export const usePostProjectContext = () => {
  return useContext(PostProjectContext);
};


const PostProjectProvider = (props) => {
  const { currentProject, fetchProjectChanges } = useProjectContext();
  const [siteId, setSiteId] = useState("")
   const [editObject, setEditObject] = useState({ chooseOption: "" });
  const {requestAccessToken}= useUserContext()
  const { units, setUnits } = useUnitContext()
  const { siteToPost, resetSiteValues } = useSiteContext();
  const [waitingForRes, setWaitingForRes] = useState(false)
  const [editingProjects, setEditingProjects]=useState(false)
  
  
  let navigate = useNavigate();
  
  const api = process.env.REACT_APP_API;

 const createProject = async (mainProject) => {
   let token = await requestAccessToken();
   let projectToPost = {
     "site": siteToPost,
     "units":units
   }
   
   try {
     let res = await fetch(api + "/project/create", {
       method: "POST",
       headers: new Headers({
         Authorization: `Bearer ${token} `,
         "Content-Type": "application/json",
       }),
       body: JSON.stringify(projectToPost),
     });
     if (res.status === 200) {
      
       let data = await res.json()
       let projectId = data.data.id
      
      
       await setUnits([])
       await resetSiteValues()
       navigate("/project/"+projectId)
       
     } else {
       console.log("Posting project failed");
     }
   } catch (e) {
     console.log(e);
     console.log("Posting project failed");
   } 
  };
  

   const createProjectExistingSite = async (mainProject) => {
     let token = await requestAccessToken();
     let projectToPost = {
       siteId: siteId,
       units: units,
       mainProject: siteToPost.mainProject
     };

     console.log("object is ", projectToPost)

     try {
       let res = await fetch(api + "/project/create/existing", {
         method: "POST",
         headers: new Headers({
           Authorization: `Bearer ${token} `,
           "Content-Type": "application/json",
         }),
         body: JSON.stringify(projectToPost),
       });
       if (res.status === 200) {
         let data = await res.json();
         let projectId = data.data.id;
         setSiteId("")
         await setUnits([]);
         await resetSiteValues();
         
         navigate("/project/" + projectId);
       } else {
         console.log("Posting project failed");
       }
     } catch (e) {
       console.log(e);
       console.log("Posting project failed");
     }
   };

  
  
   const editProject = async (siteId, projectId) => {
     let token = await requestAccessToken();
    
   
     try {
       let res = await fetch(api + "/project/edit/" + siteId, {
         method: "PUT",
         headers: new Headers({
           Authorization: `Bearer ${token} `,
           "Content-Type": "application/json",
         }),
         body: JSON.stringify({ site: siteToPost, project: currentProject}),
       });
       if (res.status === 200) {
         let data = await res.json();
         console.log(data)
         setSiteId("");
         await setUnits([]);
         await resetSiteValues()
         await fetchProjectChanges(currentProject.site.projectChangesId)
       
           return res;

        
       } else {
         console.log("Editing project failed");
       }
     } catch (e) {
       console.log(e);
       console.log("Editing project failed");
     }
  };
  
  const editProjectsInBatch = async (siteIdsArr) => {
    let token = await requestAccessToken();
    const objectToSend = {
      siteIds: siteIdsArr,
      fields:editObject
    }

    try {
      setWaitingForRes(true)
      let res = await fetch(api + "/project/edit/batch/", {
        method: "PATCH",
        headers: new Headers({
          Authorization: `Bearer ${token} `,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(objectToSend),
      });
      if (res.status === 200) {
        setWaitingForRes(false)
        let data = await res.json();
        console.log(data);
        setEditObject({ chooseOption: "" });
        setEditingProjects(!editingProjects)
        return res;
      } else {
         setWaitingForRes(false);
        console.log("Editing projects in batch failed");
      }
    } catch (e) {
      console.log(e);
      console.log("Editing projects in batch failed");
    }
  };


  const values = {
    createProject,
    createProjectExistingSite,
    siteId,
    setSiteId,
    editProject,
    editObject,
    setEditObject,
    editProjectsInBatch,
    waitingForRes,
    editingProjects,
    setEditingProjects,
  };

    return (
      <PostProjectContext.Provider value={values}>
        {props.children}
      </PostProjectContext.Provider>
    );
}

export default PostProjectProvider;