import React, { useEffect, useState, useContext } from "react";
import SSRIcon from "@ingka/ssr-icon";
import home from "@ingka/ssr-icon/paths/home";
import styled from "styled-components";
import arrows from "@ingka/ssr-icon/paths/arrow-left-arrow-right";
import location from "@ingka/ssr-icon/paths/location-pin-small";

function ProjectConnections(props) {
  const [showAll, setShowAll] = useState(true)
  const [amountLeft, setAmountLeft]=useState(0)
   useEffect(() => {
     if (props.units.length > 3) {
       setShowAll(false)
       setAmountLeft(props.units.length - 3);
    }
   }, [props]);

  return (
    <div className="connectionsWrapper" style={styles.connectionsWrapper}>
      <div className="columnName" style={styles.columnName}>
        <h5>Connections</h5>
        <SSRIcon paths={arrows} />
      </div>

      <div className="locationName" style={{ display: "flex" }}>
        <SSRIcon paths={location} />
        <h6>Site(#{props.project.site.id})</h6>
      </div>

      <p>
        {props.project.site.city}, {props.project.site.country}
      </p>
      {props.units.map((unit, index) => (
        <>
          {showAll && (
            <>
              <ConnectionsContent>
                <div className="connectionUnit" style={styles.unit}>
                  <div style={styles.text}>
                    <h7 style={styles.connection}>|</h7>
                    <h7 style={styles.connection}>|____</h7>
                  </div>

                  <div style={styles.connectionUnit}>
                    <SSRIcon
                      paths={home}
                      style={styles.location}
                      colour="grey"
                    />
                    <h6>
                      {unit.projectName}(#{unit.id})
                    </h6>
                  </div>
                </div>
              </ConnectionsContent>
            </>
          )}

          {!showAll && index < 3 && (
            <>
              <ConnectionsContent>
                <div className="connectionUnit" style={styles.unit}>
                  <div style={styles.text}>
                    <h7 style={styles.connection}>|</h7>
                    <h7 style={styles.connection}>|____</h7>
                  </div>

                  <div style={styles.connectionUnit}>
                    <SSRIcon
                      paths={home}
                      style={styles.location}
                      
                      
                    />
                    <h6>
                      {unit.projectName}(#{unit.id})
                    </h6>
                  </div>
                </div>
              </ConnectionsContent>
            </>
          )}
        </>
      ))}
      {props.units.length > 3 && (
        <button onClick={(e) => setShowAll(!showAll)}>
          {showAll ? "Show less" : `+ ${amountLeft} more`}
        </button>
      )}
    </div>
  );
}

export default ProjectConnections;


const ConnectionsContent = styled.div`
  padding: 1px;
  font-size: 12px;
  text-align: center;
  color: black;
  text-align: left;
  
`;

const styles = {
  text: {
    display: "flex",
    flexDirection: "column",
  },
  unit: {
    display: "flex",
    paddingLeft: "2vh",
  },
  connection: {
    fontSize: "2rem",
    marginTop: "-3vh",
    color: "white",
    marginLeft: "2vw",
  },
  connectionUnit: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "flex-end",
    color: "white",
   
    
  },
  location: {
    float: "left",
  },
  connectionsWrapper: {
    width: "92%",
    textAlign: "left",
    color: "white",
  },
  columnName: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "4vh",
  },
};
