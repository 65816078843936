// import React from "react";


// const Footer = () => (
// <div class="row">
//   <div class="column">
//     <h2>Column 1</h2>

//   </div>
//   <div class="column">
//     <h2>Column 2</h2>
//   </div>
// </div>


// );

// // Cookie policy          Change log          v. 0.1
// // © Ingka Digital - Business Support 2021



// export default Footer;

import React from "react";

export default function Footer({ fixed }) {

    return (
        <>

            <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
                <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">

                    <p className="text-sm leading-relaxed inline-block mr-4 py-2 whitespace-nowrap  text-black">

                        © Ingka Digital - Business Support 2022
                    </p>


                </div>

                <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                    <li className="nav-item">
                        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">

                            <p className="text-sm  leading-relaxed inline-block mr-4 py-2 whitespace-nowrap  text-black">

                                Cookie policy
                            </p>


                        </div>
                    </li>
                    <li className="nav-item">
                        <p className="text-sm  leading-relaxed inline-block mr-4 py-2 whitespace-nowrap  text-black">

                            Change log
                        </p>
                    </li>
                    <li className="nav-item">
                        <p className="text-sm  leading-relaxed inline-block mr-4 py-2 whitespace-nowrap  text-black">

                            v. 0.1
                        </p>
                    </li>
                </ul>
            </div>


        </>
    );
}