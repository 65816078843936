import React, { useEffect, useState, useContext } from "react";
import SSRIcon from "@ingka/ssr-icon";
import checkmark from "@ingka/ssr-icon/paths/checkmark-circle";

function ProjectStatus(props) {
  return (
    <div className="statusWrapper" style={styles.statusWrapper}>
      <div className="header" style={styles.header}>
        <p>Status</p>
        <SSRIcon paths={checkmark} style={{ color: "grey" }} />
      </div>
      <div className="mainContent" style={styles.mainContent}>
        <div className="item" style={styles.item}>
          <div style={{ color: "black" }}>
            <p style={{ fontWeight: "bold" }}>Construction status</p>
            <p>In progress</p>
          </div>

          <p style={{ fontSize: ".9rem" }}>2022-01-14</p>
        </div>
        <div className="item" style={styles.item}>
          <div style={{ color: "black" }}>
            <p style={{ fontWeight: "bold" }}>Site progress</p>
            <p>Approved</p>
          </div>

          <p style={{ fontSize: ".9rem" }}>2022-01-14</p>
        </div>
        <div className="item" style={styles.item}>
          <div style={{ color: "black" }}>
            <p style={{ fontWeight: "bold" }}>Design status</p>
            <p>Not started</p>
          </div>

          <p style={{ fontSize: ".9rem" }}>2022-01-14</p>
        </div>
        <div className="item" style={styles.item}>
          <div style={{ color: "black" }}>
            <p style={{ fontWeight: "bold" }}>Final IR status</p>
            <p>Approved</p>
          </div>

          <p style={{ fontSize: ".9rem" }}>2022-01-14</p>
        </div>
        <div className="item" style={styles.item}>
          <div style={{ color: "black" }}>
            <p style={{ fontWeight: "bold" }}>Design approval</p>
            <p>N/A</p>
          </div>

          <p style={{ fontSize: ".9rem" }}>2022-01-14</p>
        </div>
        <div className="item" style={styles.item}>
          <div style={{ color: "black" }}>
            <p style={{ fontWeight: "bold" }}>Country board approval</p>
            <p>Planned</p>
          </div>

          <p style={{ fontSize: ".9rem" }}>2022-01-14</p>
        </div>
      </div>
    </div>
  );
}

export default ProjectStatus;


const styles = {
  statusWrapper: {
    width: "100%",
    border: "1px solid grey",
    borderRadius: "5px",
    padding: "1vw 2vw 1vw 2vw",
    color: "grey",
    maxHeight: "50vh",
    
  
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  mainContent: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "repeat(2, 1fr)",
    rowGap:"4vh"
  }
};