import React, { createContext, useContext, useState, useEffect } from "react";
import { useUserContext } from "./UserContext";

const UnitContext = createContext("");

export const useUnitContext = () => {
  return useContext(UnitContext);
};

const UnitProvider = (props) => {
    const api = process.env.REACT_APP_API;
  const [name, setName] = useState("");
  const { requestAccessToken } = useUserContext()
  const [unitIdsToDelete, setUnitIdsToDelete] = useState([])
  const [unitSize, setUnitSize]=useState(0)

  const [units, setUnits] = useState([]);
  const [unitIndex, setUnitIndex] = useState(0);
  const [choosenUnit, setChoosenUnit] = useState({});
  const [establishmentType, setEstablishmentType] = useState("all")
  const [projectName, setProjectName] = useState("")
  const [grossUnitArea, setGrossUnitArea] = useState(0)
   const [unitsSizePerPage, setUnitsSizePerPage] = useState(3);
   const [unitsMaxPage, setUnitsMaxPage] = useState(1);
   const [unitsCurrentPage, setUnitsCurrentPage] = useState(1);


  

  function setChoosenName(name) {
    choosenUnit.projectName = name;
  }

  function setValue(valueName, value) {
    if (value !== "default") {
      units[unitIndex][valueName] = value;
    }
  }

  function pushNewUnit(unit) {
    units.push(unit);
  }
  async function getOneUnit(unitId) {
     let token = await requestAccessToken();
     console.log(token);
     try {
       let res = await fetch(api + "/unit/" + unitId, {
         method: "GET",
         headers: new Headers({
           Authorization: `Bearer ${token} `,
           "Content-Type": "application/json",
         }),
       });
       if (res.status === 200) {
         let unitRes = await res.json();
       
         return unitRes;
       } else {
         console.log("Fetching specific unit failed");
       }
     } catch (e) {
       console.log(e);
       console.log("Fetching specific unit failed");
     }
  }

  async function getAllUnits() {
    let token = await requestAccessToken();
    try {
        let res = await fetch(api + "/unit/units?page="+unitsCurrentPage+"&size="+unitsSizePerPage, {
          method: "POST",
          headers: new Headers({
            Authorization: `Bearer ${token} `,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            filter: {
              establishmentType: establishmentType,
              projectName: projectName,
              grossUnitArea: grossUnitArea,
            },
          }),
        });
      if (res.status === 200) {
        const data=await res.json()
        setUnits(data.units)
        setUnitsMaxPage(data.maxPage)
        return data.units
      }
      else {
        console.log("Fetching units failed")
      }
      
    }
    catch (e) {
       console.log(e);
       console.log("Fetching units failed");
    }
  }

 

  const values = {
    setName,

    setValue,

    pushNewUnit,
    name,

    units,
    setUnits,
    unitIndex,
    setUnitIndex,
    choosenUnit,
    setChoosenUnit,
    setChoosenName,
    unitIdsToDelete,
    setUnitIdsToDelete,
    unitSize,
    setUnitSize,
    getOneUnit,
    establishmentType,
    setEstablishmentType,
    projectName,
    setProjectName,
    grossUnitArea,
    setGrossUnitArea,
    getAllUnits,
    unitsSizePerPage,
    setUnitsSizePerPage,
    unitsMaxPage,
    unitsCurrentPage,
    setUnitsCurrentPage,
  };

  return (
    <UnitContext.Provider value={values}>{props.children}</UnitContext.Provider>
  );
};

export default UnitProvider;
