import React, { createContext, useContext, useState, useEffect } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { useUserContext } from "./UserContext";
import { radiusS } from "@ingka/variables";
import { useUnitContext } from "./UnitContext";
import { useNavigate } from "react-router-dom";

const ProjectContext = createContext("");

export const useProjectContext = () => {
  return useContext(ProjectContext);
};

const ProjectProvider = (props) => {
  let navigate = useNavigate();
  const { instance, accounts, inProgress } = useMsal();
  const { requestAccessToken } = useUserContext();
  const { name, establishmentType, unitType, units, setUnits } = useUnitContext()
  const [status, setStatus] = useState("all")
  const [country, setCountry] = useState("all");
  const [mainProject, setMainProject] = useState("");
  const [filterName, setFilterName] = useState("all")
  const [showUnitsInputs, setShowUnitsInputs] = useState(false)
  const [openSiteInputs, setOpenSiteInputs] = useState(false)
  const [getResult, setGetResult] = useState(null);
  const [currentPage, setCurrentPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [currentProjects, setCurrentProjects]=useState([])
  const [lastIndex, setLastIndex] = useState("")
  const [sizePerPage, setSizePerPage] = useState(3)
  const [projects, setProjects] = useState([])
  const [currentProject, setCurrentProject] = useState({})
  const [projectChanges, setProjectChanges] = useState({})
  const [batchProjects, setBatchProjects] = useState([])
  const [existingCountries, setExistingCountries]=useState([])
 

  const fortmatResponse = (res) => {
    return JSON.stringify(res, null, 2);
  }

  const [sites, setSites] = useState(null)

  const [siteToPost, setSiteToPost] = useState(null)
  const [city, setCity]=useState("")



  const api = process.env.REACT_APP_API;







  const fetchAllProjects = async () => {

    console.log("api is ", api)
   

    let token = await requestAccessToken();
    console.log(token)
    try {
      let res = await fetch(
        api +
          "/firestore/projects?page=" +
          currentPage +
          "&size=" +
          sizePerPage,
        {
          method: "POST",
          headers: new Headers({
            Authorization: `Bearer ${token} `,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            filter: {
              country: country,
              city: city,
              mainProject: mainProject
            }
          }),
        }
      );
      if (res.status === 200) {
        let data = await res.json();
        console.log("all projects have been fetched");
        console.log(data.data.projects);
        setMaxPage(data.data.maxPage);
        setProjects(data.data.projects);
      } else {
        console.log("Fetching projects failed");
        setProjects([]);
      }
    } catch (e) {
      console.log(e)
      console.log("Fetching projects failed");
    }
  };


  const fetchProjectChanges = async (changesId) => {
    let token = await requestAccessToken();
    try {
       let res = await fetch(api + "/site/projectChanges/" + changesId, {
         method: "GET",
         headers: new Headers({
           Authorization: `Bearer ${token} `,
           "Content-Type": "application/json",
         }),
       });
      if (res.status === 200) {
        let projectChangesRes = await res.json();
        setProjectChanges(projectChangesRes);

        console.log("project changes are ", projectChanges);
       
        return projectChanges;
      } else {
        console.log("Fetching project changes failed");
      }
    }
    catch (e) {
          console.log(e);
          console.log("Fetching project changes failed");
    }
  }


  const fetchOneProject = async (projectId) => {


    let token = await requestAccessToken();


    try {
      let res = await fetch(api + "/firestore/projects/" + projectId, {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${token} `,
          "Content-Type": "application/json",
        }),
      })
      if (res.status === 200) {

        let project = await res.json();

        console.log("project is ", project.data)
        setCurrentProject(project.data)
        return project.data

      } else {
        console.log("Fetching specific project failed");
      }
    }
    catch (e) {
      console.log(e);
      console.log("Fetching specific project failed");
    }

  }

  const fetchExistingCountries = async () => {


    let token = await requestAccessToken();

    try {
      let res = await fetch(api + "/firestore/countries", {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${token} `,
          "Content-Type": "application/json",
        })
      });
      if (res.status === 200) {

        let countries = await res.json();
        setExistingCountries(countries.countries)

        return countries.countries

      } else {
        console.log("Fetching countries failed");
      }
    } catch (e) {
      console.log(e);
      console.log("Fetching countries failed");
    }
  };

  const addFavorite = async (projectId) => {
    let token = await requestAccessToken();
    try {
      let res = await fetch(api + "/firestore/favorites/" + projectId, {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${token} `,
          "Content-Type": "application/json",
        }),
      });
      if (res.status === 200) {
        console.log("Adding favorite successfull");
      } else {
        console.log("Adding favorite projects failed");
      }
    } catch {
      console.log("Adding favorite projects failed");
    }
  };

  const fetchFavoriteProjectIds = async () => {
    let token = await requestAccessToken();
    try {
      let res = await fetch(api + "/firestore/favorites/", {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }),
      });

      if (res.status === 200) {
        console.log("all favorites have been fetched");

        return await res.json();
      }
    } catch (e) {
      console.log(e);
      console.log("Fetching favorite projects failed");
    }
  };

  const deleteFavorite = async (projectId) => {
    let token = await requestAccessToken();
    try {
      let res = await fetch(api + "/firestore/favorites/" + projectId, {
        method: "DELETE",
        headers: new Headers({
          Authorization: `Bearer ${token} `,
          "Content-Type": "application/json",
        }),
      });
      if (res.status === 200) {
        console.log("Deleting favorite ids successfull");

        return await res.json();
      } else {
        console.log("Deleting favorite projects failed");
      }
    } catch (e) {
      console.log(e);
      console.log("Deleting favorite projects failed");
    }
  };


  const addProject = async (mainProject) => {

    let token = await requestAccessToken();

    let site = {
      "country": siteToPost.country,
      "city": siteToPost.city,
      "address": "",
      "locationName": "",
      "siteType": "",
      "transactionType": siteToPost.transactionType,
      "countryProjectResponsible": siteToPost.countryProjectResponsible,
      "state": "",
      "siteProgress": "",
      "siteAccessibleDate": "",
      "landSize": 0,
      "grossBuildingArea": 0,
      "realEstateClusterResponsible": "",
      "siteStatusDetails": "",
      "partOfAnApprovedMPP": false,
      "partOfACityPlan": false,
      "approvedCityPlan": "",
      "confidential": false,
      "mainProject": siteToPost.mainProject
    };
    const firstUnit = units[0]
    let unit = {
      "projectName": firstUnit.projectName,
      "unitType": firstUnit.unitType,
      "establishmentType": firstUnit.establishmentType,
      "bussinessUnitId": 0,
      "grossUnitArea": 0,
      "projectStartDate": "",
      "designStartDate": "",
      "constructionStartDate": "",
      "firstOutboundDate": "",
      "projectClosingDate": "",
      "unifierId": 0,
      "partOfProgramme": false,
      "approvedProgramme": "",
    };

    let projectToPost = {
      "site": site,
      "unit": unit
    }
    console.log(projectToPost)
    try {
      let res = await fetch(api + "/project/create", {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${token} `,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(projectToPost),
      });
      if (res.status === 200) {
        let response = await res.json();
        let id = response.data.project_ID;
        console.log("Posting project successfull");
        await setUnits([])
        navigate("/project/" + id)


      } else {
        console.log("Posting project failed");
      }
    } catch (e) {
      console.log(e);
      console.log("Posting project failed");
    }

  };

  const values = {
    fetchAllProjects,
    addFavorite,
    fetchFavoriteProjectIds,
    deleteFavorite,
    batchProjects, setBatchProjects,

    fetchOneProject,
    setCurrentProjects,
    currentProjects,
    projects,
    setProjects,
    projectChanges, setProjectChanges,

    // fetchAllSites,

    addProject,
    setStatus,
    status,
    country,
    setCountry,
    mainProject,
    setMainProject,
    fetchExistingCountries,
    setFilterName,
    filterName,
    showUnitsInputs,
    setShowUnitsInputs,
    openSiteInputs,
    setOpenSiteInputs,

    siteToPost,
    setSiteToPost,
    city,
    setCity,
    currentPage,
    setCurrentPage,
    maxPage,
    setLastIndex,
    sizePerPage,
    setSizePerPage,
    currentProject,
    fetchProjectChanges,
    existingCountries
  };

  return (
    <ProjectContext.Provider value={values}>
      {props.children}
    </ProjectContext.Provider>
  );
};

export default ProjectProvider;
