import React, { createContext, useContext, useState, useEffect } from "react";
import { useUserContext } from "./UserContext";
import { useUnitContext } from "./UnitContext";

const SiteContext = createContext("");

export const useSiteContext = () => {
  return useContext(SiteContext);
};

const SiteProvider = (props) => {
  const { requestAccessToken } = useUserContext();
  const {unitIdsToDelete, setUnitIdsToDelete}=useUnitContext()
  const [sites, setSites] = useState([])
  const [currentChecked, setCurrentChecked] = useState("");
  const [sitesSizePerPage, setSitesSizePerPage] = useState(3)
  const [sitesMaxPage, setSitesMaxPage] = useState(1)
  const [sitesCurrentPage, setSitesCurrentPage]=useState(1)
  const [siteId, setSiteId]=useState(0)
  const api = process.env.REACT_APP_API;
  const [city, setCity] = useState("")
  const [country, setCountry] = useState("all");
  const [locationName, setLocationName] = useState("");
  const [siteToPost, setSiteToPost] = useState({
    country: "Åland Islands",
    city: "",
    transactionType: "Buy",
    countryProjectResponsible: "",
    state: "",
    address: "",
    locationName: "",
    siteType: "",
    siteProgress: "",
    siteAccessibleDate: "",
    landSize: 0,
    grossBuildingArea: 0,
    realEstateClusterResponsible:"",
    partOfAnApprovedMPP:false,
    partOfACityPlan:false,

    confidential:false,
    siteStatusDetails:"",
    mainProject:"",
  });
 
  



  function resetSiteValues(){
setSiteToPost({
  country: "Åland Islands",
  city: "",
  transactionType: "Buy",
  countryProjectResponsible: "",
  state: "",
  address: "",
  locationName: "",
  siteType: "",
  siteProgress: "",
  siteAccessibleDate: "",
  landSize: 0,
  grossBuildingArea: 0,
  realEstateClusterResponsible: "",
  partOfAnApprovedMPP: false,
  partOfACityPlan: false,

  confidential: false,
  siteStatusDetails: "",
  mainProject: "",
});
  }

  const fetchAllSites = async () => {
    

    let token = await requestAccessToken();
    console.log(token);
    try {
      let res = await fetch(api + "/site/sites?page="+sitesCurrentPage+"&size="+sitesSizePerPage, {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${token} `,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          filter: {
            country: country,
            city: city,
            locationName: locationName,
          },
        }),
      });
      if (res.status === 200) {
        let data = await res.json()
        setSites(data.sites)
        setSitesMaxPage(data.maxPage)
        return data.sites
       
      } else {
        console.log("Fetching sites failed");
      }
    } catch (e) {
      console.log(e);
      console.log("Fetching sites failed");
    }
  };


   const fetchOneSite = async (siteId) => {
     let token = await requestAccessToken();
     console.log(token);
     try {
       let res = await fetch(api + "/site/"+siteId, {
         method: "GET",
         headers: new Headers({
           Authorization: `Bearer ${token} `,
           "Content-Type": "application/json",
         }),
       });
       if (res.status === 200) {
         let sitesRes = await res.json();
        
          return sitesRes;
       } else {
         console.log("Fetching specific site failed");
       }
     } catch (e) {
       console.log(e);
       console.log("Fetching specific site failed");
     }
  };
  
    const deleteUnitFromSite = async () => {
      let token = await requestAccessToken();
      
     

      try {
         let res = await fetch(api + "/unit/delete/" + siteId, {
          method: "DELETE",
          headers: new Headers({
            Authorization: `Bearer ${token} `,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            unitsToDelete: unitIdsToDelete,
          }),
        });
        setUnitIdsToDelete([])
        if (res.status === 200) {
          return res;
        } else {
          console.log("Deleting unit failed");
        }
      } catch (e) {
        console.log(e);
        console.log("Deleting unit failed");
      }
    };
  
  
   const values = {
     fetchAllSites,
     sites,
     currentChecked,
     setCurrentChecked,
    
     siteToPost,
     setSiteToPost,
     resetSiteValues,
     fetchOneSite,
     siteId,
     setSiteId,
     deleteUnitFromSite,
     country, setCity, setCountry, setLocationName,
     city, locationName,
     sitesMaxPage,
     sitesCurrentPage,
       setSitesCurrentPage,
     sitesSizePerPage,
     setSitesSizePerPage,
   
   };

 

  return (
    <SiteContext.Provider value={values}>{props.children}</SiteContext.Provider>
  );
}

export default SiteProvider;