import React, { useState, useEffect, useContext } from "react";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import Modal from "react-bootstrap/Modal";
import { useProjectContext } from "../../../contexts/ProjectContext";
import Button from "@ingka/button";
import UnitScope from "./UnitScope";
import UnitResponsible from "./UnitResponsible";
import UnitFinancials from "./UnitFinancials";
import UnitStatus from "./UnitStatus";

import { useUnitContext } from "../../../contexts/UnitContext";


function UnitForm(props) {
  


  const [choosenType, setChoosenType] = useState("default")
  const [currName, setCurrName] = useState("");

    const {
      showUnitsInputs,
      setShowUnitsInputs,
      
      setOpenSiteInputs,
  } = useProjectContext();

  const {
    units,
    setUnits,
    unitIndex,
    setUnitIndex,
    name,
    setName,
    setChoosenUnit,
    choosenUnit,
    
    pushNewUnit,
  } = useUnitContext();
  const [showMainProject, setShowMainProject] = useState(false)

  
  


 
  async function addNewUnit() {
  
    if (choosenType !== "default") {
   
      let newUnit = {
        projectName: choosenType,
        unitType: "default",
        establishmentType: "default",
        bussinessUnitId: 0,
        grossUnitArea: 0,
        projectStartDate: "",
        designStartDate: "",
        openingDate: "",
        constructionStartDate: "",
        firstOutboundDate: "",
        projectClosingDate: "",
        unifierId: 0,
        partOfProgramme: false,
      };

    

   
      await setChoosenUnit(newUnit)
      await setUnits([...units, newUnit])
    
     
    
     

      
  
    }
   
   
  }

  

   const tabs = [
     <Tab key="tab-1" text={"Scope"} tabPanelId="tab_1" />,
     <Tab key="tab-2" text={"Responsible"} active tabPanelId="tab_2" />,
     <Tab key="tab-3" text={"Financials"} active tabPanelId="tab_3" />,
     <Tab key="tab-4" text={"Solutions"} active tabPanelId="tab_4" />,
     <Tab key="tab-5" text={"Status"} active tabPanelId="tab_5" />,
     <Tab key="tab-6" text={"Decisions"} active tabPanelId="tab_6" />,
  ];
  
  async function change(index) {
    await setChoosenUnit(units[index])
    await setUnitIndex(index)
   
  }
  async function saveUnit(data) {
   
    units[unitIndex] = data
    await setUnits(units)
  }

  
  const tabPanels = [
    <TabPanel key="tab-1" tabPanelId="tab_1">
      <UnitScope units={units} unit={units[unitIndex]} emitData={saveUnit} />
    </TabPanel>,
    <TabPanel key="tab-2" tabPanelId="tab_2">
      <UnitResponsible units={units} />
    </TabPanel>,
    <TabPanel key="tab-3" tabPanelId="tab_3">
      <UnitFinancials units={units} />
    </TabPanel>,
    <TabPanel key="tab-5" tabPanelId="tab_5">
      <UnitStatus units={units} />
    </TabPanel>,
  ];
  return (
    <>
      <label style={styles.label} className="label">
        <p>Add unit category</p>

        <select
          style={styles.input}
          onChange={(e) => setChoosenType(e.target.value)}
        >
          <option value="default">Choose unit type</option>
          <option value="Centres">Centres</option>
          <option value="Central Fulfilment Unit">
            Central Fulfilment Unit
          </option>
          <option value="Customer Meeting Point">Customer Meeting Point</option>
          <option value="Office">Office</option>
        </select>
        <Button
          type="secondary"
          small="true"
          text="Add"
          onClick={(e) => addNewUnit()}
        />
      </label>

      <div className="units" style={styles.units}>
        {units.length > 0
          ? units.map((unit, index) => (
              <div
                className="unitName"
                style={
                  unitIndex === index
                    ? styles.choosenUnitName
                    : styles.unitName
                }
                onClick={(e) => change(index)}
              >
              <p>Unit {index+1}</p>
              </div>
            ))
          : null}
      </div>

      <div className="bodyWrapper">
        {units.length>0 && (<Tabs tabs={tabs} tabPanels={tabPanels} defaultActiveTab="tab_1" />)}
        
      </div>
    </>
  );
}

export default UnitForm;

const styles = {
  label: {
    display: "grid",
    gridTemplateColumns: "20% 40% 20%",

    alignItems: "center",
    justifyContent: "center",

    padding: "4vh 0 4vh 4vw",
    width: "80%",
  },
  input: {
    border: "1px solid black",
    borderRadius: "5px",
    height: "70%",
    paddingLeft: "1vh",
  },
  units: {
    display: "flex",
    flexDirection: "row",
    gap: "1vw",
    padding: "0 1vw 0 1vw",
    fontSize: "80%",
  },
  unitName: {
    padding: "1vh 1vw 0 1vw",
    color: "#0058a3",
    backgroundColor: "rgb(243, 242, 241)",
    cursor: "pointer",
  },
  choosenUnitName: {
    padding: "1vh 1vw 0 1vw",
    color: "white",
    backgroundColor: "#0058a3",
    cursor: "pointer",
    borderRadius: "25px",
  },
  btn: {
    color: "white",
  },
};