import React, { useState, useEffect, useContext } from "react";
import Button from "@ingka/button";
import Modal from "react-bootstrap/Modal";
import { useProjectContext } from "../../contexts/ProjectContext";
import { useDecisionPointsContext } from "../../contexts/DecisionPointsContext"
import SSRIcon from "@ingka/ssr-icon";
import chevronDown from "@ingka/ssr-icon/paths/chevron-down";
import chevronUp from "@ingka/ssr-icon/paths/chevron-up";
import checkmark from "@ingka/ssr-icon/paths/checkmark-circle";
import clock from "@ingka/ssr-icon/paths/clock";
import crossCircle from "@ingka/ssr-icon/paths/cross-circle";
import cube from "@ingka/ssr-icon/paths/cube-3d";
import DecisionPointsInputs from "./DecisionPointsInputs";


function DecisionPointsModal(props) {

  const {
    fetchProjectDecisionPoints,
    projectDecisionPoints,
    DPArr,
    pointsToEdit,
    setPointsToEdit,
    editDecisionPoints,
    currentDP,
    
  } = useDecisionPointsContext();
  const { currentProject } = useProjectContext()
  const [showDPs, setShowDPs] = useState([])
  

 

  const switchStatus = (dpStatus) => {
    switch (dpStatus) {
      case "Not started":
        return <SSRIcon paths={cube} />;
      case "In progress":
        return <SSRIcon paths={clock} />;
      case "Finished":
        return <SSRIcon paths={checkmark} />;
    }
  }

  const showDP = (dp) => {
    let newArr = Object.assign(showDPs, {})
    if (!newArr.includes(dp)) {
      newArr.push(dp)
    }
    else {
      const dpPosition = newArr.indexOf(dp)
      newArr.splice(dpPosition, 1)
    }
   
    setShowDPs([...newArr])
  }

  const editDps = async() => {
    const dpId = currentProject.decisionPointsId;
    let res = await editDecisionPoints(dpId)
    if (res === "success") {
     window.location.reload(false);
      
    
    }
  }

  const allDecisionPoints = () => {
   
    return (
      <>
        <div className="decisionPointsWrapper">
          {DPArr.map((dp) => (
            <div
              className="decisionPointsItem"
              style={styles.decisionPointsItem}
            >
              <p style={{ fontWeight: "bold" }}>{dp}</p>
              <div className="dpStatus" style={styles.status}>
                <p>
                  Status -{" "}
                  {projectDecisionPoints[dp.split(" ")[0]]?.status ||
                    "Not started"}
                </p>
                <p>
                  {switchStatus(
                    projectDecisionPoints[dp.split(" ")[0]]?.status
                  )}
                </p>
              </div>
              {showDPs.includes(dp.split(" ")[0]) ? (
                <SSRIcon
                  paths={chevronUp}
                  onClick={(e) => showDP(dp.split(" ")[0])}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <SSRIcon
                  paths={chevronDown}
                  onClick={(e) => showDP(dp.split(" ")[0])}
                  style={{ cursor: "pointer" }}
                />
              )}

              {showDPs.includes(dp.split(" ")[0]) && (
                <DecisionPointsInputs
                  dp={dp.split(" ")[0]}
                  dpObject={projectDecisionPoints[dp.split(" ")[0]]}
                />
              )}
            </div>
          ))}
        </div>
      </>
    );
  }
  return (
    <>
      {projectDecisionPoints["DP0"] &&(
        <Modal
          show={props.show}
          onHide={(e) => {
            props.closeModal();
          }}
          dialogClassName="my-modal-decisionPoints"
          contentClassName="modal-height"
        >
          <Modal.Header closeButton>
            <div className="modalHeader">
              <Modal.Title>Project #{currentProject.id}</Modal.Title>
              <h5 style={{ marginTop: "5vh" }}>
                Decision points: Status - {currentDP ? `In progress ${currentDP}`: "Not started"}
              </h5>
            </div>
          </Modal.Header>

          <Modal.Body>
            <div className="bodyWrapper" style={styles.bodyWrapper}>
              {allDecisionPoints()}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="footerWrapper" style={styles.footerWrapper}>
              {pointsToEdit !== null && (
                <Button
                  type="primary"
                  small="true"
                  style={{ color: "white" }}
                  text="Save changes"
                  onClick={(e) => editDps()}
                />
              )}
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default DecisionPointsModal;

const styles = {
  decisionPointsItem: {
    display: "grid",
    gridTemplateColumns: "60% 30% 10%",
    padding: "1vw 1vw 1vw 3vw",
    marginTop: "3vh",
    border: "1px solid #BEBEBE",
  },
  status: {
    display: "flex",
    gap: "1vw",
  },
};
