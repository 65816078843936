import React, {useState, useEffect, useContext} from 'react'
import Modal from "react-bootstrap/Modal";

function ListDetailsModal(props) {

  return (
    
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.show}
        onHide={(e) => props.emitData()}
        dialogClassName="existingSiteDetails"
    >
      <div className='content' style={styles.content}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           Details site (#1234)
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Centered Modal</h4>
          <p>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
            ac consectetur ac, vestibulum at eros.
          </p>
        </Modal.Body>

      </div>
        
      </Modal>
   
  );
}

export default ListDetailsModal
const styles = {
  content: {
     backgroundColor:"#0058a3",
  minHeight:"60vh",
    color: "white",
    borderRadius: "10px",
  
  }
}