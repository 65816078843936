import React, {useState, useContext, useEffect} from 'react'
import RadioButton from "@ingka/radio-button";
import RadioButtonGroup from "@ingka/radio-button-group";
import { useProjectContext } from "../../../contexts/ProjectContext";
import { useSiteContext } from '../../../contexts/SiteContext';

function SiteAdditionalInfo(props) {

  const {siteToPost, setSiteToPost}=useSiteContext()
  const { setShowUnitsInputs, setOpenSiteInputs } = useProjectContext();
  
 
  
  

  return (
    <div className="formWrapper" style={styles.formWrapper}>
      <p>{siteToPost.partOfAnApprovedMPP}</p>
      <div className="wrapper" style={styles.wrapper}>
        <form className="form" style={styles.form}>
          <label style={styles.label} className="label">
            <p>State/Province</p>

            <input
              type="text"
              style={styles.input}
              value={siteToPost.state}
              onChange={(e) =>
                setSiteToPost({
                  ...siteToPost,
                  state: e.target.value,
                })
              }
            />
          </label>
          <label style={styles.label} className="label">
            <p>Address</p>

            <input
              type="text"
              style={styles.input}
              value={siteToPost.address}
              onChange={(e) =>
                setSiteToPost({
                  ...siteToPost,
                  address: e.target.value,
                })
              }
            />
          </label>
          <label style={styles.label} className="label">
            <p>Location name</p>

            <input
              type="text"
              style={styles.input}
              value={siteToPost.locationName}
              onChange={(e) =>
                setSiteToPost({
                  ...siteToPost,
                  locationName: e.target.value,
                })
              }
            />
          </label>
          <label style={styles.label} className="label">
            <p>Site type</p>

            <select
              style={styles.input}
              onChange={(e) =>
                setSiteToPost({
                  ...siteToPost,
                  siteType: e.target.value,
                })
              }
            >
              <option value="test">Test</option>
            </select>
          </label>
          <label style={styles.label} className="label">
            <p>Site progress</p>

            <select
              style={styles.input}
              onChange={(e) =>
                setSiteToPost({
                  ...siteToPost,
                  siteProgress: e.target.value,
                })
              }
            >
              <option value="test">Test</option>
            </select>
          </label>
          <label style={styles.label} className="label">
            <p>Site accessible date</p>

            <input
              type="text"
              style={styles.input}
              value={siteToPost.siteAccessibleDate}
              onChange={(e) =>
                setSiteToPost({
                  ...siteToPost,
                  siteAccessibleDate: e.target.value,
                })
              }
            />
          </label>
        </form>
        <form className="form" style={styles.form}>
          <label style={styles.label} className="label">
            <p>Land size (m2)</p>

            <input
              type="number"
              value={siteToPost.landSize}
              style={styles.input}
              onChange={(e) =>
                setSiteToPost({
                  ...siteToPost,
                  landSize: e.target.value,
                })
              }
            />
          </label>
          <label style={styles.label} className="label">
            <p>Gross Building area (GBA) (m2)</p>

            <input
              type="number"
              style={styles.input}
              value={siteToPost.grossBuildingArea}
              onChange={(e) =>
                setSiteToPost({
                  ...siteToPost,
                  grossBuildingArea: e.target.value,
                })
              }
            />
          </label>
          <label style={styles.label} className="label">
            <p>Real estate cluster responsible</p>

            <input
              type="text"
              style={styles.input}
              value={siteToPost.realEstateClusterResponsible}
              onChange={(e) =>
                setSiteToPost({
                  ...siteToPost,
                  realEstateClusterResponsible: e.target.value,
                })
              }
            />
          </label>
          <label style={styles.label} className="label">
            <p>Part of an approved MPP</p>

            <div className="radioButtons" style={styles.radioButtons}>
              <RadioButton
                id="radio1"
                name="groupRadios1"
                label="No"
                defaultChecked="true"
                checked={!siteToPost.partOfAnApprovedMPP}
                onChange={(e) =>
                  setSiteToPost({
                    ...siteToPost,
                    partOfAnApprovedMPP: !siteToPost.partOfAnApprovedMPP,
                  })
                }
              />
              <RadioButton
                id="radio2"
                name="groupRadios1"
                label="Yes"
                checked={siteToPost.partOfAnApprovedMPP}
                onClick={(e) =>
                  setSiteToPost({ ...siteToPost, partOfAnApprovedMPP: true })
                }
              />
            </div>
          </label>
          <label style={styles.label} className="label">
            <p>Part of a City Plan</p>

            <div className="radioButtons" style={styles.radioButtons}>
              <RadioButton
                id="radio3"
                name="groupRadios2"
                label="No"
                checked={!siteToPost.partOfACityPlan}
                onClick={(e) =>
                  setSiteToPost({ ...siteToPost, partOfACityPlan: false })
                }
              />
              <RadioButton
                id="radio4"
                name="groupRadios2"
                label="Yes"
                checked={siteToPost.partOfACityPlan}
                onClick={(e) =>
                  setSiteToPost({ ...siteToPost, partOfACityPlan: true })
                }
              />
            </div>
          </label>
          <label style={styles.label} className="label">
            <p>Confidential</p>

            <div className="radioButtons" style={styles.radioButtons}>
              <RadioButton
                id="radio5"
                name="groupRadios3"
                label="No"
                checked={!siteToPost.confidential}
                onClick={(e) =>
                  setSiteToPost({ ...siteToPost, confidential: false })
                }
              />
              <RadioButton
                id="radio6"
                name="groupRadios3"
                label="Yes"
                checked={siteToPost.confidential}
                onClick={(e) =>
                  setSiteToPost({ ...siteToPost, confidential: true })
                }
              />
            </div>
          </label>
          <label style={styles.label} className="label">
            <p>Site status details</p>

            <textarea
              style={styles.input}
              value={siteToPost.siteStatusDetails}
              onChange={(e) =>
                setSiteToPost({
                  ...siteToPost,
                  siteStatusDetails: e.target.value,
                })
              }
            />
          </label>
        </form>
      </div>
    </div>
  );
  
}

export default SiteAdditionalInfo

const styles = {
  wrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "3vw",
  },
  formWrapper: {},
  btnWrapper: {
    padding: "4vw 4vw 0 0",
    display: "grid",
    justifyItems: "end",
    marginTop: "3vh",
    cursor: "default",
  },
  btn: {
    color: "white",
    backgroundColor: "#0058a3",
    padding: "2vh 4vh 2vh 4vh",
    borderRadius: "25px",
    cursor: "pointer",
  },
  label: {
    display: "grid",
    gridTemplateColumns: "40% 50%",
    padding: "2vh",
    gap: "2vw",
    itemsAlign: "center",
    justifyContent: "center",
  },
  input: {
    border: "1px solid black",
    borderRadius: "5px",
    height: "70%",
    paddingLeft: "1vh",
  },
  radioButtons: {
    display: "flex",
    flexDirection: "row",
    gap:"2vw"
  }
};