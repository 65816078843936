import React, { createContext, useContext, useState, useEffect } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "../authConfig";
//import { msalInstance } from "../authConfig";

const UserContext = createContext("");

export const useUserContext = () => {
  return useContext(UserContext);
};

const UserProvider = (props) => {
  const { instance, accounts, inProgress } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const [userId, setUserId] = useState(null);

  const api = process.env.REACT_APP_API;
 

  useEffect(async () => {
   // await requestAccessToken();
  }, []);

  async function requestAccessToken() {
    
   
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    
    setUserId(accounts[0].homeAccountId);
    let token = "";

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    await instance
      .acquireTokenSilent(request)
      .then((response) => {
        console.log("just acquired access token")
        console.log(response.idToken);
        token = response.idToken;
        setAccessToken(response.idToken);
        
      })
      .catch((e) => {
        console.log("no token found", e)
        instance.acquireTokenPopup(request).then((response) => {
          token = response.idToken;
          setAccessToken(response.idToken);
          console.log("Token: ", response.idToken);
        });
      });

    return token;
  }

  const values = {
    requestAccessToken,
    accessToken,
    userId,
  };

  return (
    <UserContext.Provider value={values}>{props.children}</UserContext.Provider>
  );
};

export default UserProvider;
