import React, { useState, useEffect, useContext } from "react";
import { useProjectContext } from "../../contexts/ProjectContext";
import Pill from "@ingka/pill";
import cross from "@ingka/ssr-icon/paths/cross";

function FilterIndicators() {
  const {
    city,
    country,
    filterName,
    setCity,
    setCountry,
    mainProject,
    setMainProject,
  } = useProjectContext();

  const [color, setColor] = useState("");
  const [color2, setColor2] = useState("");
  const [color3, setColor3] = useState("");

  const styleCross = {
    color: color,
  };

  const styleCross2 = {
    color: color2,
  };

  const styleCross3 = {
    color: color3,
  };

  return (
    <div className="indicatorsWrapper" style={styles.indicatorsWrapper}>
      {country && country !== "all" && (
        <div className="itemWrapper">
          {/* <p>
            Country: {country}{" "}
            <span
              className="removeFilter"
              style={styles.removeFilter}
              onClick={async (e) => await setCountry("all")}
            >
              x
            </span>
          </p> */}

          <button
            class="pill pill--small leading-icon pill--active"
            aria-pressed="true"
          >
            <svg
              style={styleCross}
              focusable="false"
              viewBox="0 0 24 24"
              class="svg-icon pill__icon"
              aria-hidden="true"
              onMouseEnter={() => setColor("#5e5eaa")}
              onMouseLeave={() => setColor("")}
              onClick={async (e) => await setCountry("all")}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="m11.9997 13.4149 4.9498 4.9497 1.4142-1.4142-4.9498-4.9497 4.9498-4.9498-1.4142-1.4142-4.9498 4.9498L7.05 5.6367 5.6357 7.051l4.9498 4.9498-4.9497 4.9497L7.05 18.3646l4.9497-4.9497z"
              ></path>
            </svg>
            <span class="pill__label">{country}</span>
          </button>
        </div>
      )}
      {city && city !== "all" && (
        <div className="itemWrapper">
          {/* <p>
            City: {city}{" "}
            <span
              className="removeFilter"
              style={styles.removeFilter}
              onClick={async (e) => await setCity("")}
            >
              x
            </span>
          </p> */}

          {/* <Pill small={true} label={city} ssrIcon={cross} selected={true} iconPosition="leading" onClick={async (e) => await setCity("")}/> */}

          <button
            class="pill pill--small leading-icon pill--active"
            aria-pressed="true"
          >
            <svg
              style={styleCross2}
              focusable="false"
              viewBox="0 0 24 24"
              class="svg-icon pill__icon"
              aria-hidden="true"
              onMouseEnter={() => setColor2("#5e5eaa")}
              onMouseLeave={() => setColor2("")}
              onClick={async (e) => await setCity("")}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="m11.9997 13.4149 4.9498 4.9497 1.4142-1.4142-4.9498-4.9497 4.9498-4.9498-1.4142-1.4142-4.9498 4.9498L7.05 5.6367 5.6357 7.051l4.9498 4.9498-4.9497 4.9497L7.05 18.3646l4.9497-4.9497z"
              ></path>
            </svg>
            <span class="pill__label">{city}</span>
          </button>
        </div>
      )}
      {mainProject && mainProject !== "" && (
        <div className="itemWrapper">
          {/* <p>
            Main project: {mainProject}{" "}
            <span
              className="removeFilter"
              style={styles.removeFilter}
              onClick={async(e) => await setMainProject("")}
            >
              x
            </span>
          </p> */}

          <button
            class="pill pill--small leading-icon pill--active"
            aria-pressed="true"
          >
            <svg
              style={styleCross3}
              focusable="false"
              viewBox="0 0 24 24"
              class="svg-icon pill__icon"
              aria-hidden="true"
              onMouseEnter={() => setColor3("#5e5eaa")}
              onMouseLeave={() => setColor3("")}
              onClick={async (e) => await setMainProject("")}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="m11.9997 13.4149 4.9498 4.9497 1.4142-1.4142-4.9498-4.9497 4.9498-4.9498-1.4142-1.4142-4.9498 4.9498L7.05 5.6367 5.6357 7.051l4.9498 4.9498-4.9497 4.9497L7.05 18.3646l4.9497-4.9497z"
              ></path>
            </svg>
            <span class="pill__label">{mainProject}</span>
          </button>
        </div>
      )}
    </div>
  );
}

export default FilterIndicators;

const styles = {
  itemWrapper: {
    backgroundColor: "#0058a3",
    color: "white",
    padding: "0 1vw 0 1vw",
    borderRadius: "5px",
    height: "4vh",
  },
  removeFilter: {
    paddingLeft: "1vw",
    cursor: "pointer",
  },
  indicatorsWrapper: {
    display: "flex",
    gap: "2vw",
  },
};
