import React, { createContext, useContext, useState, useEffect } from "react";
import { useUnitContext } from "../contexts/UnitContext";
import {
  BrowserRouter as Router,
  useParams,
  useNavigate,
} from "react-router-dom";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import Table from "@ingka/table";
import { TableHeader, TableBody } from "@ingka/table";


function UnitDetailsPage() {
  const { getOneUnit } = useUnitContext()
  const { unitId } = useParams();
  const [unit, setUnit]=useState({})
  
  useEffect(async () => {
    const unitRes = await getOneUnit(unitId)
    setUnit(unitRes)
    console.log("unit is ", unitRes)
  }, [unitId])
  
    const tabs = [
      <div className="tab-container">
        <Tab key="tab-1" text={"Overview"} tabPanelId="tab_1" />
        <Tab
          key="tab-2"
          text={"Solutions"}
          active
          tabPanelId="tab_2"
        />
       
      </div>,
  ];
  
  const tabPanels = [
    <TabPanel key="tab-1" tabPanelId="tab_1" title="">
      <div className="overviewWrapper" style={styles.overviewWrapper}>
        <div className="left" style={styles.detailsColumn}>
          <p style={styles.weight}>Unit type</p>
          <p>{unit.unitType}</p>
          <p style={styles.weight}>Unifier ID</p>
          <p>{unit.unitiferID}</p>
          <p style={styles.weight}>Project name</p>
          <p>{unit.projectName}</p>
          <p style={styles.weight}>Part of programme</p>
          <p>{unit.partOfProgramme ? "Yes" : "No"}</p>
          <p style={styles.weight}>Establishment type</p>
          <p>{unit.establishmentType}</p>
          <p style={styles.weight}>Business unit ID</p>
          <p>{unit.bussinessUnitId}</p>
        </div>
        <div className="right" style={styles.detailsColumn}>
          <p style={styles.weight}>Construction start date</p>
          <p>{unit.constructionStartDate}</p>
          <p style={styles.weight}>Design start date</p>
          <p>{unit.designStartDate}</p>
          <p style={styles.weight}>First outbound date</p>
          <p>{unit.firstOutboundDate}</p>
          <p style={styles.weight}>Gross unit area (m2)</p>
          <p>{unit.grossUnitArea}</p>
          <p style={styles.weight}>Project start date</p>
          <p>{unit.projectStartDate}</p>
          <p style={styles.weight}>Project closing date</p>
          <p>{unit.projectClosingDate}</p>
        </div>
      </div>
    </TabPanel>,
    <TabPanel key="tab-2" tabPanelId="tab_2" title=""></TabPanel>,
  ];

  return (
    <div className="unitWrapper" style={styles.unitWrapper}>
      {unit && (
        <>
          <h2>Unit #({unitId})</h2>
          <div className="detailsWrapper">
            <Tabs tabs={tabs} tabPanels={tabPanels} defaultActiveTab="tab_1" />
          </div>
        </>
      )}
    </div>
  );
}

export default UnitDetailsPage;

const styles = {
  unitWrapper: {
    width: "100%",

    padding: "20vh 7vh 7vh 7vh",
  },
  overviewWrapper: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "1vw",
    justifyItems: "center",
  },
  detailsColumn: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",

    justifyItems: "start",
    columnGap: "8vw",
    rowGap: "2vh",
  },
  weight: {
    fontWeight: "bold",
  },
};
