import React, { createContext, useEffect, useState, useRef } from "react";
import Button from "@ingka/button";
import magnifyingGlassList from "@ingka/ssr-icon/paths/magnifying-glass-list";
import DropdownMenu from "./Dropdown";
import Pill from "@ingka/pill";
import filters from "@ingka/ssr-icon/paths/filters";
import cross from "@ingka/ssr-icon/paths/cross";

import styled from "styled-components";
import ModalSkapa, {
  Sheets,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "@ingka/modal";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/modal/dist/style.css";
import "@ingka/focus/dist/style.css";

import { useProjectContext } from "../contexts/ProjectContext";

const Container = styled.div``;

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vw;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
`;

const Modal = styled.div`
  background: #fff;
  position: absolute;
  right: 0;
  padding: 20px;
  min-height: 100%;
  width: 30%;
`;

const Sidebar = () => {
  const {
    setMainProject,
    setCity,
    setCountry,
    fetchExistingCountries,
    country,
    city,
    mainProject,
  } = useProjectContext();

  const resetOnSelect = (e) => {
    e.preventDefault();
    setMainProject("");
    setCity("");
    setCountry("all");
  };

  const [showSidebar, setShowSidebar] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      {/* <Button onClick={() => { this.setState({modalOpen: true}) }} text="Re-open"/> */}
      <Pill
        type="emphasised"
        onClick={() => (modalOpen ? setModalOpen(false) : setModalOpen(true))}
        selected={false}
        iconPosition="trailing"
        ssrIcon={filters}
        label="Filters"
        style={styles.browseBtn}
      />

      <>
        <ModalSkapa
          visible={modalOpen}
          handleCloseBtn={(event) => {
            setModalOpen(false);
          }}
          style={{ zIndex: "2000" }}
        >
          <Sheets
            alignment={"right"}
            // preserveAlignment={preserveAlignment}
            size={"small"}
            header={<ModalHeader title="Filter and sort" />}
            footer={
              // compact={}

              <ModalFooter>
                <Button
                  text="Clear all"
                  type="primary"
                  onClick={resetOnSelect}
                  style={{ color: "white", fontWeight: "bold" }}
                />
              </ModalFooter>
            }
          >
            <ModalBody>
              <DropdownMenu />
            </ModalBody>
          </Sheets>

          {/* <h4>Filter and sort</h4> */}
          {/* <Button
                ssrIcon={cross}
                iconOnly={true}
                type="tertiary"
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "10px",
                  top: "2px",
                }}
                onClick={() => setShowSidebar(!showSidebar)}
              /> */}

          {/* <DropdownMenu /> */}
        </ModalSkapa>
      </>
    </>
  );
};

const styles = {
  sidebar: {
    backgroundColor: "#0058a3",
    zIndex: "2000",
    height: "100%",
    overflowY: "scroll",
  },
  browseBtn: {
    color: "black",
  },
};

export default Sidebar;
