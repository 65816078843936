import React, { createContext, useContext, useState, useEffect } from "react";
import { useUserContext } from "./UserContext";
import { useProjectContext } from "./ProjectContext";
const DecisionPointsContext = createContext("");

export const useDecisionPointsContext = () => {
  return useContext(DecisionPointsContext);
};

const DecisionPointsProvider = (props) => {

   const api = process.env.REACT_APP_API;
  const { requestAccessToken } = useUserContext();
  const { currentProject } = useProjectContext();
  const [projectDecisionPoints, setProjectDecisionPoints] = useState({})
  const [currentDP, setCurrentDP] = useState("")
  const [pointsToEdit, setPointsToEdit] = useState(null)

    const DPArr = [
      "DP0 Approve Project Brief and Risk Money request",
      "DP1 Approve site selection",
      "DP2 Approve Project Plan and appendices",
      "DP3 Approve Concept Design",
      "DP4 Approve Site IR",
      "DP5 Approve Developed Design",
      "DP6 Approve Final IR",
      "DP7 Approve start of construction and fit-out & build-up",
      "DP8 Approve opening of unit",
      "DP9 Approve closure of construction and fit-out & build-up",
      "DP10 Approve project closure",
    ];
  
   const fetchProjectDecisionPoints = async (decisionPointsId) => {
      let token = await requestAccessToken();
     
      try {
        let res = await fetch(api + "/decisionPoints/"+decisionPointsId, {
          method: "GET",
          headers: new Headers({
            Authorization: `Bearer ${token} `,
            "Content-Type": "application/json",
          }),
        });
        if (res.status === 200) {
          let decisionPointsRes = await res.json();
          

          setProjectDecisionPoints(decisionPointsRes.decisionPoints);
          if (decisionPointsRes.DPInProgress) {
             setCurrentDP(decisionPointsRes.DPInProgress);
          }
          else {
            setCurrentDP("DP0")
          }
             
         
            
          
      
          
        } else {
          console.log("Fetching decision points failed");
        }
      } catch (e) {
        console.log(e);
        console.log("Fetching decision points failed");
      }
  };


   const fetchDecisionPointInput = async (decisionPointName) => {
     let token = await requestAccessToken();

     try {
       let res = await fetch(api + "/decisionPoints/input/" + decisionPointName, {
         method: "GET",
         headers: new Headers({
           Authorization: `Bearer ${token} `,
           "Content-Type": "application/json",
         }),
       });
       if (res.status === 200) {
         let decisionPointRes = await res.json();
          
         return decisionPointRes
       } else {
         console.log("Fetching decision point input failed");
       }
     } catch (e) {
       console.log(e);
       console.log("Fetching decision point input failed");
     }
   };

  
    const editDecisionPoints = async (decisionPointId) => {
      let token = await requestAccessToken();
      const dataToSend = { DPs: pointsToEdit };
      //console.log("data to send is ", dataToSend)
      try {
        let res = await fetch(
          api + "/decisionPoints/" + decisionPointId,
          {
            method: "PUT",
            headers: new Headers({
              Authorization: `Bearer ${token} `,
              "Content-Type": "application/json",
            }),
            body: JSON.stringify(dataToSend)
          }
        );
        if (res.status === 200) {
           
          return "success"
          
        } else {
          console.log("Editing decision points failed");
        }
      } catch (e) {
        console.log(e);
        console.log("Editing decision points failed");
      }
  };
  

  const values = {
    fetchProjectDecisionPoints,
    projectDecisionPoints,
    setProjectDecisionPoints,
    DPArr,
    fetchDecisionPointInput,
    currentDP,
    setCurrentDP,
    pointsToEdit,
    setPointsToEdit,
    editDecisionPoints,
  };

    return (
      <DecisionPointsContext.Provider value={values}>
        {props.children}
      </DecisionPointsContext.Provider>
    );

}

export default DecisionPointsProvider;