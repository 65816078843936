import React, { createContext, useContext, useState, useEffect } from "react";
import ikea from "../images/ikea.svg.png";
import account from "@ingka/ssr-icon/paths/account";
import SSRIcon from "@ingka/ssr-icon";

import { Link } from "react-router-dom";
import { SignOutButton } from "./SignOutButton";
import Button from "@ingka/button";

import { HashLink } from "react-router-hash-link";

import { scroller } from "react-scroll";

import styles2 from "../components/navbar.scss";

import { loginRequest } from "../authConfig";
import { useMsal } from "@azure/msal-react";
import { useUserContext } from "../contexts/UserContext";
import { useNavigate } from "react-router-dom";

import {
  Link as Scrolllink,
  animateScroll as scroll,
  Element,
} from "react-scroll";

import { StickyNav } from "react-js-stickynav";
import "react-js-stickynav/dist/index.css";

import "../components/navbar.scss";

export default function Navbar() {
  const { requestAccessToken, accessToken } = useUserContext();

  const { instance, accounts, inProgress } = useMsal();
  const name = accounts[0] && accounts[0].name;
  let navigate = useNavigate();

  const [navbarOpen] = React.useState(false);

  return (
    <>
      <div className="wrapper" style={styles.nav}>
        <div class="shadow bottom">
          <nav className="relative flex flex-wrap items-center justify-between px-1 py-3">
            <div className="container px-0 mx-auto flex flex-wrap items-center justify-between">
              <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"></div>

              <div
                className={
                  "lg:flex flex-grow items-center" +
                  (navbarOpen ? " flex" : " hidden")
                }
                id="example-navbar-danger"
              >
                <div className="hrefLogo" style={styles.hrefLogo}>
                  <Link to="/">
                    <img
                      className="logotype"
                      src={ikea}
                      alt="ikea logo"
                      style={styles.logotype}
                    />
                  </Link>
                </div>
                <div className="text-sm font-bold leading-relaxed inline-block ml-10 py-3 whitespace-nowrap  text-black ">
                  Expansion Tracker
                </div>
                <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                  <li className="nav-item">
                    <HashLink to="/#projects">
                      <Button
                        type="tertiary"
                        text="Projects"
                        small={true}
                        style={{ fontWeight: "bold" }}
                      />
                    </HashLink>
                  </li>

                  <li className="nav-item">
                    <Button
                      type="tertiary"
                      text="Sites"
                      small={true}
                      style={{ fontWeight: "bold" }}
                      onClick={(e) => navigate("/sites")}
                    />
                  </li>

                  <li className="nav-item">
                    <Button
                      type="tertiary"
                      text="Units"
                      small={true}
                      style={{ fontWeight: "bold" }}
                      onClick={(e) => navigate("/units")}
                    />
                  </li>

                  <li className="nav-item" style={styles.navbarName}>
                    <div className="px-3 py-3 flex items-center text-xs  font-bold leading-snug text-black hover:opacity-75">
                      {name}
                      <i className="text-black opacity-75"></i>
                      <span className="ml-2">
                        <SSRIcon paths={account} />
                      </span>
                    </div>
                  </li>
                  {name ? (
                    <div>
                      <li>
                        <SignOutButton />
                      </li>
                    </div>
                  ) : null}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

const styles = {
  logotype: {
    maxWidth: "100%",
  },

  hrefLogo: {
    maxWidth: "10%",
  },

  nav: {
    position: "fixed",
    zIndex: "2000",
    width: "100%",
    top: 0,
    background: "rgb(254, 255, 255)",
  },

  navbarName: {
    pointerEvents: "none",
  },
};
