import React, { useState, useEffect, useContext } from "react";
import Button from "@ingka/button";
import Modal from "react-bootstrap/Modal";
import { usePostProjectContext } from "../../../contexts/PostProjectContext"
import { useSiteContext } from "../../../contexts/SiteContext";
import ClipLoader from "react-spinners/ClipLoader";

import BatchInputList from "./BatchInputList"
import { useProjectContext } from "../../../contexts/ProjectContext";

function EditInBatchModal(props) {

const {
  editObject,
  editProjectsInBatch,
  waitingForRes,
  } = usePostProjectContext();
  const { batchProjects, setBatchProjects, fetchAllProjects } =
    useProjectContext();

  const [warning, setWarning]=useState(false)

  async function closeModal() {
  
    props.emitData()
  }

  async function submitValues() {
    let foundEmptyValue=false
    for (const [key, value] of Object.entries(editObject)) {
  if (key === "chooseOption" || value === "") {
    console.log("found empty value ")
    foundEmptyValue=true
    break
    }
    }
    
    if (foundEmptyValue) {
      setWarning(true)
       setTimeout(function () {
         setWarning(false);
       }, 5000);
    }
    else {
     let siteIdsArr=[]
      batchProjects.forEach((project) => {
        if (!siteIdsArr.includes(project.site.id)) {
          siteIdsArr.push(project.site.id)
        }
        
      })
      let res=await editProjectsInBatch(siteIdsArr);
      if (res.status === 200) {
       
        props.success()
     
      }
      
    }
  }

  return (
    <>
      {props.projects && props.projects.length >0 && (
        <Modal
          show={props.show}
          onHide={(e) => {
            closeModal();
          }}
          dialogClassName="my-modal"
          contentClassName="modal-height"
        >
          <Modal.Header closeButton>
            <Modal.Title>Editing projects</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="bodyWrapper" style={styles.bodyWrapper}>
              <div className="projectsList" style={styles.projectsList}>
                <p style={{ fontWeight: "bold" }}>Projects chosen:</p>
                {props.projects.map((project) => (
                  <div className="projectItem" style={styles.projectItem}>
                    <p style={styles.p}>#{project.id} - {project.site.mainProject}</p>
                  </div>
                ))}
              </div>
              <BatchInputList />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="footerWrapper" style={styles.footerWrapper}>
              <Button
                type="emphasised"
                text={waitingForRes ? "Saving changes..." : "Save changes"}
                disabled={waitingForRes}
                className="primaryBtn"
                small="true"
                onClick={(e) => submitValues()}
              />
              {warning && (
                <p style={{ fontWeight: "bold", marginTop: "2vh" }}>
                  Please fill in all the empty values
                </p>
              )}
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default EditInBatchModal;

const styles = {
  bodyWrapper: {
    display: "grid",
    gridTemplateColumns: "30% 70%",
    marginTop: "10vh",
    padding: "5vh",
    gap: "5vw",
  },
  projectItem: {
    fontWeight: "bold",
    height: "10vh",
    textAlign: "center",
    borderBottom: "#A9A9A9 solid 1px",
    boxShadow: "0 4px 8px -2px #A9A9A9",
  },
  projectsList: {
    display: "flex",
    flexDirection: "column",
    gap: "4vh",
    height: "50vh",
    overflowY: "scroll",
  },
  p: {
    paddingTop: "2vh",
  },
  footerWrapper: {
    display: "flex",
    flexDirection:"column"
  }
};
