import React, { useEffect, useState, useContext } from "react";
import JumboButton from "@ingka/jumbo-button";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/focus/dist/style.css";
import "@ingka/jumbo-button/dist/style.css";
import ProjectChanges from "../components/ProjectDetailPage/ProjectChanges";


import ProjectStatus from "../components/ProjectDetailPage/ProjectStatus";

import home from "@ingka/ssr-icon/paths/home";

import { useProjectContext } from "../contexts/ProjectContext";
import DecisionPoints from "../components/ProjectDetailPage/DecisionPoints"
import SSRIcon from "@ingka/ssr-icon";
import pencil from "@ingka/ssr-icon/paths/pencil";
import ProjectOverview from "../components/ProjectDetailPage/ProjectOverview";
import { useDecisionPointsContext } from "../contexts/DecisionPointsContext";


import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/carousel/dist/style.css";
import "@ingka/tabs/dist/style.css";
import "@ingka/focus/dist/style.css";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import Button from "@ingka/button";
import ProjectConnections from "../components/ProjectDetailPage/ProjectConnections";

import { useLocation } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";

import styled from "styled-components";
import EditProjectModal from "../components/EditProject/EditProjectModal"

export default function DetailedPage() {
  const [showModal, setShowModal] = React.useState(false);
  const [project, setProject] = useState({});
  const [showSuccessfull, setShowSuccessfull] = useState(false)
  const { fetchProjectDecisionPoints, projectDecisionPoints, currentDP } =
    useDecisionPointsContext();

  const location2 = useLocation();
  const { fetchOneProject } = useProjectContext();
  let { projectId } = useParams();
 

 

  useEffect(async () => {
    let specificProject = await fetchOneProject(projectId);
    setProject(specificProject);
    if (specificProject.decisionPointsId) {
      await fetchProjectDecisionPoints(specificProject.decisionPointsId)
    }
     
  }, []);

 
 async function isEditingDone(isSuccessfull) {
    if (isSuccessfull) {
       let specificProject = await fetchOneProject(projectId);
    setProject(specificProject);

      setShowSuccessfull(true)
        setTimeout(function () {
          setShowSuccessfull(false)
        }, 5000);
  }
}


  return (
    <div className="project-page" style={styles.pageWrapper}>
      {project && project.site && projectDecisionPoints && currentDP ? (
        <>
          <div className="header" style={styles.header}>
            <h2>Project Page: {project.id}</h2>
            <Button
              type="emphasised"
              text="Manage project"
              small="true"
              style={{ color: "white" }}
              className="primaryBtn"
              onClick={() => setShowModal(true)}
            >
              <SSRIcon paths={pencil} style={{ marginLeft: "1vw" }} />
            </Button>
          </div>
          {showModal && (
            <EditProjectModal
              project={project}
              show={showModal}
              closeModal={(e) => setShowModal(false)}
              editSuccess={isEditingDone}
            />
          )}
          {showSuccessfull && (
            <div className="editedSuccessfully" style={styles.success}>
              <p>Changes have been saved</p>
            </div>
          )}

          <div className="decisionsWrapper" style={styles.decisionsWrapper}>
            <DecisionPoints />
          </div>
          <div className="mainContent" style={styles.mainContent}>
            <div className="mainWrapper" style={styles.mainWrapper}>
              <ProjectOverview currentProject={project} />

              <ProjectStatus />
            </div>
            {project && project.site && project.site.units.length > 0 && (
              <div style={styles.connectionsAndChanges}>
                <ProjectConnections
                  units={project.site.units}
                  project={project}
                />
                <ProjectChanges changesId={project.site.projectChangesId }/>
              </div>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}



const styles = {
  success: {
    height: "10vh",
    position: "fixed",
    bottom: "50px",
    backgroundColor: "rgb(166, 209, 168)",
    color: "white",
    padding: "2vh 2vh 0 2vh",
    fontWeight: "bold",
    right: "5%",
  },
  decisionsWrapper: {
    paddingLeft: "3vw",
    marginTop: "8vh",
  },
  mainWrapper: {
    width: "100%",
    padding: "2vw 3vw 2vw 3vw",
    display: "grid",
    
    gridTemplateRows: "50vh 50vh",
   gap:"2vh"
  },
  pageWrapper: {
    marginTop: "10vw",
  },
  header: {
    display: "flex",
    flexDirection: "row",

    width: "50%",
    marginLeft: "40vw",
    justifyContent: "space-between",
  },
  connectionsAndChanges: {
    backgroundColor: "#0058a3",
    width: "85%",
    borderRadius:"5px",
    padding: "2vh",
   marginTop:"4vh"
  },
  mainContent: {
    display: "grid",
    gridTemplateColumns: "60% 40%",
    paddingBottom: "10vh",
    
  }
};
