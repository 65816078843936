import React, { createContext, useContext, useState, useEffect } from "react";
import { useUserContext } from "./UserContext";

const ColumnContext = createContext("");

export const useColumnContext = () => {
  return useContext(ColumnContext);
};

const ColumnProvider = (props) => {
    const api = process.env.REACT_APP_API;
  const [userColumns, setUserColumns] = useState({})
  const {requestAccessToken}=useUserContext()
  const [columns, setColumns] = useState({
    id: true,
    mainProject: true,
    state: true,
    city: true,
    country: true,
    countryProjectResponsible: true,

    confidential: false,
    address: false,
    grossBuildingArea: false,
    landSize: false,
    locationName: false,
    partOfAnApprovedMPP: false,
    partOfACityPlan: false,
    realEstateClusterResponsible: false,
    siteAccessibleDate: false,
    siteProgress: false,
    siteStatusDetails: false,
    siteType: false,
    transactionType: false,
  });
 
 

  useEffect(async () => {
    await fetchUserColumns()
  },[])
  
    const fetchUserColumns = async () => {
      let token = await requestAccessToken();
     
      try {
        let res = await fetch(api + "/user/columns", {
          method: "GET",
          headers: new Headers({
            Authorization: `Bearer ${token} `,
            "Content-Type": "application/json",
          }),
        });
        if (res.status === 200) {
          let userColumns1 = await res.json();

          await setUserColumns(userColumns1)
      
          
        } else {
          console.log("Fetching columns failed");
        }
      } catch (e) {
        console.log(e);
        console.log("Fetching columns failed");
      }
  };
  

   const addNewColumn = async (columnName) => {
     let token = await requestAccessToken();

     try {
       let res = await fetch(api + "/user/columns/"+columnName, {
         method: "POST",
         headers: new Headers({
           Authorization: `Bearer ${token} `,
           "Content-Type": "application/json",
         }),
       });
       if (res.status === 200) {
         let userColumns1 = await res.json();

         setUserColumns(userColumns1);
        
         
       } else {
         console.log("Adding columns failed");
       }
     } catch (e) {
       console.log(e);
       console.log("Adding columns failed");
     }
  };
  

     const removeColumn = async (columnName) => {
       let token = await requestAccessToken();

       try {
         let res = await fetch(api + "/user/columns/" + columnName, {
           method: "DELETE",
           headers: new Headers({
             Authorization: `Bearer ${token} `,
             "Content-Type": "application/json",
           }),
         });
         if (res.status === 200) {
           let userColumns1 = await res.json();

           setUserColumns(userColumns1);
          
           
         } else {
           console.log("Removing columns failed");
         }
       } catch (e) {
         console.log(e);
         console.log("Removing columns failed");
       }
  };
  

     const resetColumns = async () => {
       let token = await requestAccessToken();

       try {
         let res = await fetch(api + "/user/columns", {
           method: "PATCH",
           headers: new Headers({
             Authorization: `Bearer ${token} `,
             "Content-Type": "application/json",
           }),
         });
         if (res.status === 200) {
          
           let userColumnsRes = await res.json();

           setUserColumns(userColumnsRes);
         } else {
           console.log("Resetting columns failed");
         }
       } catch (e) {
         console.log(e);
         console.log("Resetting columns failed");
       }
     };
 

  const values = {
    columns,
    setColumns,
    
    fetchUserColumns,
    userColumns,
    setUserColumns,
    addNewColumn,
    removeColumn,
    resetColumns,
  };

  return (
    <ColumnContext.Provider value={values}>
      {props.children}
    </ColumnContext.Provider>
  );
};

export default ColumnProvider;
