import React, { useState, useEffect } from "react";
import Search from "@ingka/search";

import Select, { Option, OptGroup } from "@ingka/select";
import ColumnSelecter from "./HomePage/ColumnSelecter";
import Button from "@ingka/button";
import FilterIndicators from "./HomePage/FilterIndicators";
import Sidebar from "../components/Sidebar";
import Pill from "@ingka/pill";

import { useProjectContext } from "../contexts/ProjectContext";

function BrowseDetails(props) {
  const { sizePerPage, setSizePerPage, setCurrentPage } = useProjectContext();

  return (
    <div className="browseWrapper">
      <div className="textContainer" style={styles.textContainer}>
        <p style={{ marginTop: "0.7vw" }}>Projects Shown : </p>

        <input
          style={styles.input}
          type="number"
          value={sizePerPage}
          onChange={(e) => setSizePerPage(e.target.value)}
        />
      </div>

      {/* <div className="columns">
        <ColumnSelecter />
      </div> */}

      {/* <div className="indicators" style={styles.indicators}>
        <FilterIndicators />
      
      </div> */}
    </div>
  );
}

export default BrowseDetails;

const styles = {
  // browseWrapper: {
  //   padding: "0 5vw 0 5vw",
  //   display: "grid",
  //   gridTemplateColumns: "repeat(2, 1fr)",
  //   width: "100%",
  //   gap: "3vw",
  // },

  input: {
    border: "0.4px solid #e4e2e2",

    marginLeft: "1vw",

    width: "15%",
    borderRadius: "5px",
  },

  // pageSize: {
  //   display: "flex",
  //   flexDirection: "row",
  //   alignItems: "center",
  //   gap: "2vw",
  // },
  textContainer: {
    height: "3.5rem",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    borderRadius: "64px",
    padding: "1vh 1vw 1vh 1vw",
    fontWeight: "bold",
    fontSize: "0.875rem",

    verticalAlign: "top",
    color: "#111111",
    outline: "0",
    border: "1px solid #f5f5f5",
    position: "relative",
    lineHeight: "1.4285714286",
    width: "80%",

    display: "flex",
    justifyContent: "center",
  },
  // indicators: {
  //   justifySelf:"start"
  // }
};
