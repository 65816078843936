import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { useProjectContext } from "../../../contexts/ProjectContext";
import { useUnitContext } from "../../../contexts/UnitContext";
import { useSiteContext } from "../../../contexts/SiteContext";
import Button from "@ingka/button";




function MainProject(props) {

  const { units, setUnits } = useUnitContext();
  const {siteToPost, setSiteToPost}=useSiteContext()

  useEffect(async () => {
  
  }, []);




  function selectMainProject(mainProject) {
    if (mainProject !== "default") {
      setSiteToPost({ ...siteToPost, mainProject: mainProject })
      
    }
    else {
      setSiteToPost({ ...siteToPost, mainProject: "" });
    }
    
 }
 
  return (
    <>
      <div className="bodyWrapper" style={styles.bodyWrapper}>
        {units.length > 0 && (
          <>
           
            <p>Chooese main project</p>
            <select
              style={styles.select}
              onChange={(e) => selectMainProject(e.target.value)}
            >
              <option value="default">Choose value</option>
              {units.map((unit, index) => (
                <>
                  <option value={unit.projectName}>{unit.projectName}</option>
                </>
              ))}
            </select>
          </>
        )}
        {units.length === 0 && (<>
        
        <p>There are no units yet</p>
        </>)}
      </div>
    </>
  );
}

export default MainProject

const styles = {
  select: {
    border: "1px solid black",
    borderRadius: "5px",
    height: "6vh",
    width: "20%",
    paddingLeft: "1vh",
  },
  btn: {
    color: "white",
  },
  footerWrapper: {
    display: "grid",
    gridGap: "50%",
  },
  wrapper: {},
  bodyWrapper: {
   display:"grid",
    alignItems: "center",
    justifyItems: "center",
    paddingTop:"4vh"
  }
};