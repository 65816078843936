import React, { useState, useEffect, useContext } from "react";
import Select, { Option, OptGroup } from "@ingka/select";
import { Countries } from "../../../countries"
import {usePostProjectContext} from "../../../contexts/PostProjectContext"

function BatchInputValidation(props) {
 
  const transactionTypes = ["Buy", "Cancel lease", "Divest", "Lease", "No transaction"]
  const { editObject, setEditObject } = usePostProjectContext()
  const [fieldName, setFieldName]=useState("")
  
  useEffect(async() => {
    await setFieldName(props.field)
  }, [props.field])
 
  async function changeObjectValue(fieldValue) {
   
    let copy = Object.assign(editObject, {})
    copy[fieldName] = fieldValue
    await setEditObject({ ...copy })
    
  }
 

   const functionWithSwitch = () => {
     switch (props.field) {
       case "country":
         return (
           <Select
             label=""
             className="selcetField"
             onChange={(e)=> changeObjectValue(e.target.value)}
             >{
               Countries.map((data, key) => {
                 return (
                   <Option
                     value={data.name}
                     name={data.name}
                   />
                 );
               })
           }
           </Select>
         );
       case "transactionType":
         return (
           <Select
             label=""
             className="selcetField"
             onChange={(e) => changeObjectValue(e.target.value)}
           >
             {transactionTypes.map((data) => {
               return <Option value={data} name={data} />;
             })}
           </Select>
         );
       case "siteType":
         return (
           <Select
             label=""
             className="selcetField"
             onChange={(e) => changeObjectValue(e.target.value)}
           >
             <Option value="test" name="test" />
             <Option value="test1" name="test1" />
           </Select>
         );
       case "siteProgress":
         return (
           <Select
             label=""
             className="selcetField"
             onChange={(e) => changeObjectValue(e.target.value)}
           >
             <Option value="test" name="test" />
             <Option value="test1" name="test1" />
           </Select>
         );
       case "landSize":
         return (
           <input
             type="number"
             style={styles.input}
             className="fieldInput"
             oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
             onChange={(e) => changeObjectValue(e.target.value)}
             
             
           />
         );
       case "grossBuildingArea":
         return (
           <input
             type="number"
             style={styles.input}
             className="fieldInput"
             onChange={(e) => changeObjectValue(e.target.value)}
           />
         );
       case "confidential":
         return (
           <Select
             label=""
             className="selcetField"
             onChange={(e) => changeObjectValue(e.target.value)}
           >
             <Option value="true" name="Yes" />
             <Option value="false" name="No" />
           </Select>
         );
       case "partOfACityPlan":
         return (
           <Select
             label=""
             className="selcetField"
             onChange={(e) => changeObjectValue(e.target.value)}
           >
             <Option value="true" name="Yes" />
             <Option value="false" name="No" />
           </Select>
         );
       case "partOfAnApprovedMPP":
         return (
           <Select
             label=""
             className="selcetField"
             onChange={(e) => changeObjectValue(e.target.value)}
           >
             <Option value="true" name="Yes" />
             <Option value="false" name="No" />
           </Select>
         );
       case "siteStatusDetails":
         return (
           <textarea
             style={styles.input}
             type="text"
             onChange={(e) => changeObjectValue(e.target.value)}
           />
         );
       default:
         return (
           <input
             type="text"
             style={styles.input}
             className="fieldInput"
             onChange={(e) => changeObjectValue(e.target.value)}
             maxLength="50"
           />
         );
     }
   };

  return (
    <>
      {props.field && (
        <div>
          {
           functionWithSwitch()
          }
        </div>
      )}
    </>
  );
}

export default BatchInputValidation;

const styles = {
  input: {
    border: "1px solid black",
    borderRadius: "5px",


    paddingLeft: "1vh",
  },
};
