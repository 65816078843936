import React, { useState, useContext, useEffect } from 'react'
import { useSiteContext } from "../../../contexts/SiteContext";
import Checkbox from "@ingka/checkbox";
import ListDetailsModal from './ListDetailsModal';
import Modal from "react-bootstrap/Modal";
import { usePostProjectContext } from '../../../contexts/PostProjectContext';
import Button from "@ingka/button";
import SiteDetails from './SiteDetails';



function ExistingSitesList() {

  const { fetchAllSites, sites, fetchOneSite } = useSiteContext();
  const [showModal, setShowModal] = useState(false)
  const [currentSite, setCurrentSite] = useState({})
  const { setSiteId, siteId, } = usePostProjectContext();
  const [showDetails, setShowDetails]=useState(false)

  const [currentlyShowing, setCurrentlyShowing]=useState({})
 
  function pullData() {
    setShowModal(false)
  }

    useEffect(async () => {
     await fetchAllSites();
    }, []);
  
  async function handleChecked(id) {
    
    if (siteId === id) {
      await setSiteId("")
     
    }
    else {
      await setSiteId(id)
      
    }
    
  }

  function handleShowModal(site) {
    setShowModal(true)
    setCurrentSite(site)
  }

  async function handleShowInfo(siteId) {
     let specificProject = await fetchOneSite(siteId);
    setCurrentlyShowing(specificProject)
    setShowDetails(true)
  
  }
  
  return (
    <>
      <div className="sitesWrapper" style={styles.wrapper}>
        <h5>Choose site:</h5>
        <div className="browse" style={styles.browse}>
          Browse
        </div>
        {!showDetails && (
          <>
            {sites && sites.length > 0 ? (
              <div style={styles.scroll} className="sitesList">
                {sites.map((data, key) => {
                  return (
                    <div className="siteItem" style={styles.siteItem}>
                      <p>Site(#{data.id}):</p>
                      <p>Site type: {data.siteType}</p>
                      <p>City: {data.city}</p>
                      <p>Location: {data.locationName}</p>

                      <Checkbox
                        id={data.id}
                        onChange={(e) => {
                          handleChecked(data.id);
                        }}
                        checked={siteId === data.id}
                      />
                      <button
                        style={styles.detailsBtn}
                        onClick={(e) => handleShowInfo(data.id)}
                      >
                        Show more details
                      </button>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p>There are no sites at this moment</p>
            )}
          </>
        )}
        {showDetails && (
          <>
            <div className="siteDetails" style={styles.scroll}>
              <h5 style={styles.bold}>Site #({currentlyShowing.id})</h5>
              <SiteDetails site={currentlyShowing} />
            </div>
            <div className="btnBack" style={styles.btnBack}>
              
              <Button
              type="secondary"
              onClick={(e) => setShowDetails(false)}
              text="← Back"
              small="true"
            />
     </div>
            
          </>
        )}
      </div>
      <ListDetailsModal
        show={showModal}
        emitData={pullData}
        site={currentSite}
      />
    </>
  );
}

export default ExistingSitesList

const styles = {
  siteItem: {
    display: "grid",
    gridDirection: "column",
    gridTemplateColumns: "repeat(5, 1fr)",
    padding: "2vh 0 2vh 0",
    alignItems: "center",
    justifyItems: "center",
    border: "1px gray solid",
    marginTop: "2vh",
    borderRadius: "10px",
  },
  wrapper: {
    width: "80%",
    display: "inline-block",
    margin: "0 auto",
  },
  detailsBtn: {
    fontSize: "1vw",
    backgroundColor: "#0058a3",
    borderRadius: "25px",
    textAlign: "center",
    padding: "2px",
    width: "80%",
    color: "white",
  },
  browse: {
    padding: "4vh 0 4vh 0",
  },
  scroll: {
    overflowY: "scroll",
    height: "50vh",
    padding: "2vh",
  },
  btnBack: {
    marginTop:"3vh"
  },
  bold: {
    fontWeight:"bold"
  }
};