import React, { useEffect, useState, useContext } from "react";
import Stepper from "@mui/material/Stepper";
import chevronDown from "@ingka/ssr-icon/paths/chevron-down";
import chevronUp from "@ingka/ssr-icon/paths/chevron-up";
import Step from "@mui/material/Step";
import SSRIcon from "@ingka/ssr-icon";
import StepLabel from "@mui/material/StepLabel";
import DecisionPointsModal from "./DecisionPointsModal";
import { useDecisionPointsContext } from "../../contexts/DecisionPointsContext";
import { useProjectContext } from "../../contexts/ProjectContext";
import SuccessPopover from "../SuccessPopover";

function DecisionPoints(props) {
  const [showStepper, setShowStepper] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const { fetchProjectDecisionPoints, currentDP } = useDecisionPointsContext();
   const { currentProject,  } = useProjectContext();
  const steps = ["DP0", "DP1", "DP2", "DP3", "DP4", "DP5", "DP6", "DP7", "DP8", "DP9", "DP10"];
  const [showSuccess, setShowSuccess] = useState(false);

  
   useEffect(async () => {
     if (currentProject.decisionPointsId) {
       await fetchProjectDecisionPoints(currentProject.decisionPointsId);
     
       
     }
   }, []);
  
  function changesSaved() {
    setShowSuccess(true)
     setTimeout(function () {
       setShowSuccess(false);
     }, 5000);
  }
  
  return (
    <div className="pointsWrapper" style={styles.pointsWrapper}>
      <p onClick={(e) => setShowStepper(!showStepper)} style={styles.p}>
        Show decision points{" "}
        {showStepper ? (
          <SSRIcon paths={chevronUp} />
        ) : (
          <SSRIcon paths={chevronDown} />
        )}
      </p>
      {showStepper && (
        <div className="stepperWrapper">
          <Stepper activeStep={steps.indexOf(currentDP)} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel icon={label.split("P")[1]}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <p style={styles.showDetails} onClick={(e) => setShowModal(true)}>
            Show details{" "}
          </p>
        </div>
      )}
      {showModal && (
        <DecisionPointsModal
          show={showModal}
          closeModal={(e) => setShowModal(false)}
          success={(e) => changesSaved()}
        />
      )}
      <SuccessPopover show={showSuccess} />
    </div>
  );
}

export default DecisionPoints;

const styles = {
  pointsWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  p: {
    alignSelf: "flex-start",
    color: "grey",
  },
  showDetails: {
    color: "grey",
    marginTop: "4vh",
    cursor:"pointer"
  },
};
