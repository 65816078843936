import React, { useEffect, useState, useContext } from "react";
import globe from "@ingka/ssr-icon/paths/globe";
import SSRIcon from "@ingka/ssr-icon";
import { useDecisionPointsContext } from "../../contexts/DecisionPointsContext";

function ProjectOverview(props) {
  const { currentDP, projectDecisionPoints } = useDecisionPointsContext()
  
  return (
    <div className="overviewWrapper" style={styles.overviewWrapper}>
      <div className="header" style={styles.header}>
        <p>Overview</p>
        <SSRIcon paths={globe} style={{ color: "grey" }} />
      </div>
      <div className="mainContent" style={styles.mainContent}>
        <div className="contentItem" style={styles.contentItem}>
          <p style={styles.info}>Main project</p>
          <p>{props.currentProject.site.mainProject}</p>
        </div>
        <div className="contentItem" style={styles.contentItem}>
          <p style={styles.info}>Project id</p>
          <p>{props.currentProject.id}</p>
        </div>
        <div className="contentItem" style={styles.contentItem}>
          <p style={styles.info}>M-tool id</p>
          <p>{props.currentProject.MToolID ?? "-"}</p>
        </div>
        <div className="contentItem" style={styles.contentItem}>
          <p style={styles.info}>Business unit id</p>
          <p>{props.currentProject.businessUnitID ?? "-"}</p>
        </div>
        <div className="contentItem" style={styles.contentItem}>
          <p style={styles.info}>Opening FY-tertial</p>
          <p>{"-"}</p>
        </div>
        <div className="contentItem" style={styles.contentItem}>
          <p style={styles.info}>CBD name</p>
          <p>{"-"}</p>
        </div>
        <div className="contentItem" style={styles.contentItem}>
          <p style={styles.info}>Unifier id (#CMT)</p>
          <p>{"-"}</p>
        </div>
        <div className="contentItem" style={styles.contentItem}>
          <p style={styles.info}>Project status</p>
          <p>
            {currentDP === "DP0" || currentDP === "DP10"? projectDecisionPoints[currentDP].status : "In progress"}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ProjectOverview;

const styles = {
  overviewWrapper: {
    width: "100%",
    border: "1px solid grey",
    borderRadius: "5px",
    padding: "1vw 2vw 1vw 2vw",
    color: "grey",
    maxHeight:"40vh"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  mainContent: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridTemplateRows: "repeat(2, 1fr)",
    rowGap:"3vh"
  },
  info: {
    fontWeight: "bold",
  },
  contentItem: {
    color:"black"
  }
};
