import React from 'react'

function UnitResponsible(props) {
  return (
    <div className="wrapper">
      {props.units.length > 0 ? (
        <>
          {" "}
          <label style={styles.label} className="label">
            <p>Country project responsible</p>

            <input type="text" style={styles.input} />
          </label>
          <label style={styles.label} className="label">
            <p>Project orderer</p>

            <input type="text" style={styles.input} />
          </label>
          <label style={styles.label} className="label">
            <p>Project owner</p>

            <input type="text" style={styles.input} />
          </label>
          <label style={styles.label} className="label">
            <p>Project manager/leader</p>

            <input type="text" style={styles.input} />
          </label>
          <label style={styles.label} className="label">
            <p>Cluster real estate manager</p>

            <input type="text" style={styles.input} />
          </label>
          <label style={styles.label} className="label">
            <p>Real estate development leader</p>

            <input type="text" style={styles.input} />
          </label>
          <label style={styles.label} className="label">
            <p>Construction project manager</p>

            <input type="text" style={styles.input} />
          </label>
          <label style={styles.label} className="label">
            <p>Digital expansion leader/specialist</p>

            <input type="text" style={styles.input} />
          </label>
         
        
        </>
      ) : null}
    </div>
  );
}

export default UnitResponsible

const styles = {
  label: {
    display: "grid",
    gridTemplateColumns: "30% 30% 30%",
    padding: "2vh",
    gap: "2vw",
    itemsAlign: "center",
    justifyContent: "center",
  },
  input: {
    border: "1px solid black",
    borderRadius: "5px",
    height: "70%",
    paddingLeft: "1vh",
  },
};