import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "@ingka/button";

function handleLogout(instance) {
  instance.logoutRedirect().catch((e) => {
    console.error(e);
  });
}

/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
export const SignOutButton = () => {
  const { instance } = useMsal();

  return (
    <Button
    type="secondary"
      small={true}
      onClick={() => handleLogout(instance)}
      style={styles.button}
    >
      Sign out 
    </Button>
  );
};

const styles = {
  button: {
    width: "130%"
  }
}
