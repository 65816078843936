import React, { useState, useEffect, useContext } from "react";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import Modal from "react-bootstrap/Modal";
import Mandatory from "./SiteMandatory";
import SiteReview from "./SiteReview";
import SiteAdditionalInfo from "./SiteAdditionalInfo";
import { useProjectContext } from "../../../contexts/ProjectContext";

function SiteInputs(props) {

  const [activeTab, setActiveTab] = useState("tab_1")
  const { openSiteInputs, setOpenSiteInputs } = useProjectContext();



   const tabs = [
     <Tab key="tab-1" text={"Mandatory"} tabPanelId="tab_1" />,
     <Tab key="tab-2" text={"Review"} active tabPanelId="tab_2" />,
     <Tab key="tab-3" text={"Additional information"} active tabPanelId="tab_3" />,
  ];
  
  function pullData(data){
   
    setActiveTab(data)
  }
 

  const tabPanels = [
    <TabPanel
      key="tab-1"
      tabPanelId="tab_1"
      
    >
      <>
        <Mandatory emitData={pullData}/>
        </>
    </TabPanel>,
    <TabPanel
      key="tab-2"
      tabPanelId="tab_2"
     
    >
  <SiteReview emitData={pullData}/>


    </TabPanel>,
    <TabPanel
      key="tab-3"
      tabPanelId="tab_3"
    
    >
      <SiteAdditionalInfo emitData={pullData}/>
    </TabPanel>,
  ];

  function closeModal() {
    setOpenSiteInputs(false)
    setActiveTab("tab_1")
  }
  return (
    <>
      <Modal
        show={openSiteInputs}
        onHide={(e) => {
          closeModal();
        }}
        dialogClassName="my-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Site</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="bodyWrapper">
            <Tabs tabs={tabs} tabPanels={tabPanels} activeTab={activeTab} />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="footerWrapper"></div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SiteInputs;

const styles = {
  modal: {
    width:"80vw !important"
  }
}
