import React, { useState, useEffect, useContext } from "react";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";

function UnitStatus(props) {
   const tabs = [
     <Tab key="tab-1" text={"Status"} tabPanelId="tab_1" />,
     <Tab key="tab-2" text={"Dates"} active tabPanelId="tab_2" />,
     <Tab key="tab-3" text={"Comments"} active tabPanelId="tab_3" />,
   ];
  
  
  const tabPanels = [
    <TabPanel key="tab-1" tabPanelId="tab_1">
      <label style={styles.label} className="label">
        <p>Project status</p>

        <select style={styles.input}>
          <option value="test">Test</option>
        </select>
      </label>
      <label style={styles.label} className="label">
        <p>Feasibility study status</p>

        <select style={styles.input}>
          <option value="test">Test</option>
        </select>
      </label>
      <label style={styles.label} className="label">
        <p>Site evaluation status</p>

        <select style={styles.input}>
          <option value="test">Test</option>
        </select>
      </label>
      <label style={styles.label} className="label">
        <p>Concept design status</p>

        <select style={styles.input}>
          <option value="test">Test</option>
        </select>
      </label>
      <label style={styles.label} className="label">
        <p>Schematic design status</p>

        <select style={styles.input}>
          <option value="test">Test</option>
        </select>
      </label>
      <label style={styles.label} className="label">
        <p>Developed design status</p>

        <select style={styles.input}>
          <option value="test">Test</option>
        </select>
      </label>
      <label style={styles.label} className="label">
        <p>Technical design status</p>

        <select style={styles.input}>
          <option value="test">Test</option>
        </select>
      </label>
      <label style={styles.label} className="label">
        <p>Rough layout status</p>

        <select style={styles.input}>
          <option value="test">Test</option>
        </select>
      </label>
      <label style={styles.label} className="label">
        <p>Fine layout status</p>

        <select style={styles.input}>
          <option value="test">Test</option>
        </select>
      </label>
      <label style={styles.label} className="label">
        <p>Build Progress</p>

        <select style={styles.input}>
          <option value="test">Test</option>
        </select>
      </label>
      <label style={styles.label} className="label">
        <p>Fit-out & Build-up Progress</p>

        <select style={styles.input}>
          <option value="test">Test</option>
        </select>
      </label>
      <label style={styles.label} className="label">
        <p>Defects & Warrenties Managment Progress</p>

        <select style={styles.input}>
          <option value="test">Test</option>
        </select>
      </label>
    </TabPanel>,
    <TabPanel key="tab-2" tabPanelId="tab_2">
      <label style={styles.label} className="label">
        <p>Project Status Date</p>

        <input type="text" style={styles.input} />
      </label>

      <label style={styles.label} className="label">
        <p>Feasibility study progress status date</p>

        <input type="text" style={styles.input} />
      </label>
      <label style={styles.label} className="label">
        <p>Site evaluation status date</p>

        <input type="text" style={styles.input} />
      </label>
      <label style={styles.label} className="label">
        <p>Concept design progress status date</p>

        <input type="text" style={styles.input} />
      </label>
      <label style={styles.label} className="label">
        <p>Schematic design progress date</p>

        <input type="text" style={styles.input} />
      </label>
      <label style={styles.label} className="label">
        <p>Developed design progress status date</p>

        <input type="text" style={styles.input} />
      </label>
      <label style={styles.label} className="label">
        <p>Technical design progress date</p>

        <input type="text" style={styles.input} />
      </label>
      <label style={styles.label} className="label">
        <p>Rough layout progress status date</p>

        <input type="text" style={styles.input} />
      </label>
      <label style={styles.label} className="label">
        <p>Fine layout progress date</p>

        <input type="text" style={styles.input} />
      </label>
      <label style={styles.label} className="label">
        <p>Build Progress status date</p>

        <input type="text" style={styles.input} />
      </label>
      <label style={styles.label} className="label">
        <p>Fit-out & Build-up Progress status date</p>

        <input type="text" style={styles.input} />
      </label>
      <label style={styles.label} className="label">
        <p>Defects & Warrenties Managment Progress Date</p>

        <input type="text" style={styles.input} />
      </label>
    </TabPanel>,
    <TabPanel key="tab-3" tabPanelId="tab_3">
      <label style={styles.label} className="label">
        <p>Country project status comments</p>

        <textarea style={styles.textArea} />
      </label>
      <label style={styles.label} className="label">
        <p>Country project status history</p>

        <textarea style={styles.textArea} />
      </label>
      <label style={styles.label} className="label">
        <p>Centers project status comments</p>

        <textarea style={styles.textArea} />
      </label>
      <label style={styles.label} className="label">
        <p>Centers project status history</p>

        <textarea style={styles.textArea} />
      </label>
      <label style={styles.label} className="label">
        <p>Construction status comments</p>

        <textarea style={styles.textArea} />
      </label>
    </TabPanel>,
  ];
  return (<>
    {props.units.length>0 && ( <Tabs tabs={tabs} tabPanels={tabPanels} defaultActiveTab="tab_1" />)}
   
  </>)
}

export default UnitStatus;
const styles = {
  label: {
    display: "grid",
    gridTemplateColumns: "40% 30%",
    padding: "2vh 0 2vh 0",

    itemsAlign: "center",
    justifyContent: "center",
  },
  input: {
    border: "1px solid black",
    borderRadius: "5px",
    height: "70%",
    paddingLeft: "1vh",
  },
  textArea: {
    minHeight: "15vh",
    minWidth: "30vw",
    border: "1px solid black",
    borderRadius: "5px",
    padding: "1vh",
  },
};