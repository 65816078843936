import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "@ingka/button";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import SiteInputs from "./SiteInputs";
import { useProjectContext } from "../../../contexts/ProjectContext";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Typography from "@mui/material/Typography";
import Mandatory from "./SiteMandatory";
import SiteAdditionalInfo from "./SiteAdditionalInfo";
import UnitForm from "../Units/UnitForm";
import MainProject from "../Units/MainProject";
import { useUnitContext } from "../../../contexts/UnitContext";

import ExistingSitesList from "./ExistingSitesList";
import { useSiteContext } from "../../../contexts/SiteContext";
import { usePostProjectContext } from "../../../contexts/PostProjectContext";
import Checkbox from "@ingka/checkbox";
import arrowRight from "@ingka/ssr-icon/paths/arrow-right";

import RadioButtonGroup from "@ingka/radio-button-group";
import FormField from "@ingka/form-field";

function SiteForm(props) {
  const RadioButtonsArray = [
    {
      id: "firstRadio",
      name: "groupRadios",
      label: "New site",
      value: "ON",
      disabled: false,
      checked: true,
    },
    {
      id: "secondRadio",
      name: "groupRadios",
      label: "Existing site",
      value: "OFF",
      disabled: false,
    },
  ];

  const steps = ["Site", "Units", "Main Project"];

  const { currentChecked, setCurrentChecked, siteToPost, resetSiteValues } =
    useSiteContext();
  const { units, setUnits } = useUnitContext();
  const { openSiteInputs, setOpenSiteInputs } = useProjectContext();
  const { createProject, siteId, setSiteId, createProjectExistingSite } =
    usePostProjectContext();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [changeMainProject, setChangeMainProject] = useState(false);

  const [warning, setWarning] = useState(false);
  const [warningText, setWarningText] = useState("");

  const [newProject, setNewProject] = useState(null);
  const [newSite, setNewSite] = useState(null);
  const [next, setNext] = useState(false);

  const [showed, setShowed] = useState(false);

  const [existingSite, setExistingSite] = useState(false);

  const siteTabs = [
    <Tab key="tab-1" text={"Madnatory"} tabPanelId="tab_1" />,
    <Tab
      key="tab-2"
      text={"Additional information"}
      active
      tabPanelId="tab_2"
    />,
  ];

  const siteTabPanels = [
    <TabPanel key="tab-1" tabPanelId="tab_1">
      <Mandatory />
    </TabPanel>,

    <TabPanel key="tab-2" tabPanelId="tab_2">
      <SiteAdditionalInfo />
    </TabPanel>,
  ];

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /*    const handleStep = (step) => () => {
     setActiveStep(step);
   }; */

  const handleNext = () => {
    if (activeStep === 1 && units.length === 0) {
      setWarning(true);
      setWarningText("Please choose at least one unit in order to continue");
      setTimeout(function () {
        setWarning(false);
      }, 5000);
    } else {
      if (
        siteToPost.city === "" ||
        siteToPost.country === "" ||
        siteToPost.countryProjectResponsible === "" ||
        siteToPost.transactionType === ""
      ) {
        setWarning(true);
        setWarningText(
          "Please fill in all the values: country, city, transaction type and country project responsible in order to continue"
        );
        setTimeout(function () {
          setWarning(false);
        }, 5000);
      } else {
        setWarning(false);
        const newActiveStep =
          isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed,
              // find the first step that has been completed
              steps.findIndex((step, i) => !(i in completed))
            : activeStep + 1;
        setActiveStep(newActiveStep);
        console.log(units);
      }
    }
  };

  const handleNextExisting = () => {
    if (siteId === "") {
      setWarning(true);
      setWarningText("Please select one site in order to continue");
      setTimeout(function () {
        setWarning(false);
      }, 5000);
    } else {
      setWarning(false);
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    }
  };

  const handleComplete = async () => {
    let notFilled = false;

    units.forEach((unit, index) => {
      if (
        unit.projectName === "" ||
        unit.unitType === "default" ||
        unit.establishmentType === "default" ||
        unit.projectStartDate === "" ||
        unit.openingDate === "" ||
        siteToPost.mainProject === ""
      ) {
        setWarningText(
          "Please fill in values: project name, unit type, establishment type, project start date and opening date"
        );
        setWarning(true);
        notFilled = true;

        setTimeout(function () {
          setWarning(false);
        }, 5000);
      }
    });
    if (!notFilled) {
      await createProject();
    }
  };

  const handleCompleteExisting = async () => {
    if (units.length > 0) {
      let notFilled = false;

      units.forEach((unit, index) => {
        if (
          unit.projectName === "" ||
          unit.unitType === "" ||
          unit.estabishmentType === "" ||
          unit.projectStartDate === "" ||
          unit.openingDate === ""
        ) {
          setWarningText(
            "Please fill in values: project name, unit type, establishment type, project start date and opening date"
          );
          setWarning(true);
          notFilled = true;

          setTimeout(function () {
            setWarning(false);
          }, 5000);
        }
      });
      if (!notFilled) {
        await createProjectExistingSite();
      }
    } else {
      await createProjectExistingSite();
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  async function closeModal() {
    props.emitData();
    setCurrentChecked("");
    await setUnits([]);
    await setSiteId("");
    setActiveStep(0);
    setNewProject(null);
    setNewSite(null);
    resetSiteValues();
    setNext(false);
    setShowed(false);
  }

  const pullData = () => {
    setShowForm(false);
  };

  async function handleChangeSiteValue(value) {
    console.log(value);
    await setNewSite(value);
    setActiveStep(0);
    setSiteId("");
    setUnits([]);

    setCurrentChecked("");

    resetSiteValues();
  }

  return (
    <>
      <Modal
        show={props.open}
        onHide={(e) => {
          closeModal();
        }}
        dialogClassName="my-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title w-100 text-center">
            Create project
          </Modal.Title>
        </Modal.Header>

        <div style={showed ? { display: "none" } : { display: "block" }}>
          <div
            className="btnWrapper  w-100 text-center"
            style={styles.btnWrapper}
          >
            <p style={{ fontWeight: "bold", width: "51%" }}>
              Choose project type
            </p>

            <div className="w3-show-inline-block  w-100 text-center">
              <div className="w3-bar">
                <Button
                  text="New project"
                  onClick={(e) => setNewProject(true)}
                  style={styles.btn}
                  type={newProject === true ? "emphasised" : "secondary"}
                />

                <Button
                  text="SISAN project"
                  onClick={(e) => setNewProject(false)}
                  style={styles.btn}
                  type={newProject === false ? "emphasised" : "secondary"}
                />
              </div>
            </div>
          </div>

          <div
            className="btnWrapperSite"
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              width: "54%",
            }}
          >
            <div className="">
              <p style={{ fontWeight: "bold", width: "100%" }}>Choose option</p>

              {/* <RadioButtonGroup name={false} list={RadioButtonsArray} />
               */}

              <div class="form-field">
                <fieldset role="radiogroup" class="radio-button-group">
                  <div class="radio">
                    <input
                      type="radio"
                      onClick={(e) => handleChangeSiteValue(true)}
                      value="radio button value 1"
                      name="radioname"
                      id="radioButton-example-1"
                    />
                    <span class="radio__symbol"></span>
                    <span class="radio__label">
                      <label for="radioButton-example-1" class="">
                        New site
                      </label>
                    </span>
                  </div>
                  <div class="radio">
                    <input
                      type="radio"
                      onClick={(e) => handleChangeSiteValue(false)}
                      value="radio button value 2"
                      name="radioname"
                      id="radioButton-example-2"
                    />
                    <span class="radio__symbol"></span>
                    <span class="radio__label">
                      <label for="radioButton-example-2" class="">
                        Existing Site
                      </label>
                    </span>
                  </div>
                </fieldset>
              </div>
            </div>
            {/*          
          <Button
          type={newSite === true ? "emphasised" : "emphasised"}
          text="New Site"
          onClick={(e) => handleChangeSiteValue(true)}
          style={styles.btn}
            small="true"
          />
          <Button
          type={newSite === false ? "emphasised" : "emphasised"}
          text="Existing site"
            onClick={(e) => handleChangeSiteValue(false)}
            style={styles.btn}
            small="true"
          /> */}
          </div>
        </div>

        {newSite && newProject && next && (
          <Box sx={{ width: "95%", padding: "2vh" }}>
            <Stepper nonLinear activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                  <StepButton color="inherit">{label}</StepButton>
                </Step>
              ))}
            </Stepper>
            <div>
              {allStepsCompleted() ? (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleReset}>Reset</Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    {activeStep === 0 && (
                      <>
                        <Tabs
                          tabs={siteTabs}
                          tabPanels={siteTabPanels}
                          defaultActiveTab="tab_1"
                        />
                      </>
                    )}
                    {activeStep === 1 && (
                      <>
                        <UnitForm />
                      </>
                    )}
                    {activeStep === 2 && (
                      <>
                        <MainProject />
                      </>
                    )}
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      small={true}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    {activeStep !== 2 && (
                      <>
                        <Button
                          onClick={handleNext}
                          text="Next"
                          type="primary"
                          small={true}
                          style={{ color: "white" }}
                          ssrIcon={arrowRight}
                          iconPosition="trailing"
                        />
                      </>
                    )}

                    {activeStep !== steps.length &&
                      (completed[activeStep] ? (
                        <Typography
                          variant="caption"
                          sx={{ display: "inline-block" }}
                        >
                          Step {activeStep + 1} already completed
                        </Typography>
                      ) : (
                        <>
                          {activeStep === 2 && units.length > 0 && (
                            <>
                              <Button
                                onClick={handleComplete}
                                text="Create project"
                                small={true}
                                type="primary"
                                style={{ color: "white" }}
                              />
                            </>
                          )}
                        </>
                      ))}
                  </Box>
                </React.Fragment>
              )}
            </div>
          </Box>
        )}
        {newProject && newSite === false && next && (
          <Box sx={{ width: "95%", padding: "2vh" }}>
            <Stepper nonLinear activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                  <StepButton color="inherit">{label}</StepButton>
                </Step>
              ))}
            </Stepper>
            <div>
              {allStepsCompleted() ? (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleReset}>Reset</Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    {activeStep === 0 && (
                      <div
                        className="existingSitesWrapper"
                        style={styles.wrapper}
                      >
                        <ExistingSitesList />
                      </div>
                    )}
                    {activeStep === 1 && (
                      <>
                        <UnitForm />
                      </>
                    )}
                    {activeStep === 2 && (
                      <>
                        <div
                          className="changeMainProject"
                          style={styles.changeMainProject}
                        >
                          <p>Change main project</p>
                          <Checkbox
                            onChange={(e) => {
                              setChangeMainProject(!changeMainProject);
                            }}
                            checked={changeMainProject}
                          />
                        </div>
                        {changeMainProject && <MainProject />}
                      </>
                    )}
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      small={true}
                      sx={{ mr: 1 }}
                      text="Back"
                    />

                    <Box sx={{ flex: "1 1 auto" }} />

                    {activeStep !== 2 && (
                      <Button
                        onClick={handleNextExisting}
                        text="Next"
                        type="primary"
                        small={true}
                        style={{ color: "white" }}
                        ssrIcon={arrowRight}
                        iconPosition="trailing"
                      />
                    )}

                    {activeStep !== steps.length &&
                      (completed[activeStep] ? (
                        <Typography
                          variant="caption"
                          sx={{ display: "inline-block" }}
                        >
                          Step {activeStep + 1} already completed
                        </Typography>
                      ) : (
                        <>
                          {activeStep === 2 && (
                            <>
                              <Button
                                onClick={handleCompleteExisting}
                                small={true}
                                type="primary"
                                style={{ color: "white" }}
                                text="Create project"
                              />
                            </>
                          )}
                        </>
                      ))}
                  </Box>
                </React.Fragment>
              )}
            </div>
          </Box>
        )}
        <Modal.Footer>
          <div className="footerWrapper" style={styles.footerWrapper}></div>

          <div className="warning">
            {warning && warningText && (
              <>
                <h6>{warningText}</h6>
              </>
            )}
          </div>

          <div
            className="nextBtn"
            style={showed ? { display: "none" } : { display: "block" }}
          >
            {newProject && newSite && (
              <Button
                text="Next"
                type="primary"
                small={true}
                style={{ color: "white" }}
                ssrIcon={arrowRight}
                iconPosition="trailing"
                onClick={() => {
                  setNext(true);
                  setShowed(true);
                }}
              />
            )}

            {newProject && newSite === false && (
              <Button
                text="Next"
                type="primary"
                small={true}
                style={{ color: "white" }}
                ssrIcon={arrowRight}
                iconPosition="trailing"
                onClick={() => {
                  setNext(true);
                  setShowed(true);
                }}
              />
            )}
          </div>
        </Modal.Footer>
      </Modal>
      <SiteInputs open={showForm} emitData={pullData} />
    </>
  );
}

export default SiteForm;

const styles = {
  footerWrapper: {
    display: "flex",
    flexDirection: "row",
    marginRight: "4vw",
  },
  btnWrapper: {
    padding: "2vh 2vh 5vh 8vh",

    display: "block",
    gridTemplateColumns: "repeat(3, 1fr)",

    alignItems: "center",
  },
  btn: {
    width: "30%",
    fontWeight: "bold",
    fontSize: "15px",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
  },
  changeMainProject: {
    display: "flex",
    alignItems: "baseline",
    justifyItems: "center",
    flexDirection: "row",
    gap: "5vh",
    padding: "2vw",
  },
};
