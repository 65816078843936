import React, { useEffect, useState, useContext } from "react";
import clock from "@ingka/ssr-icon/paths/clock";
import SSRIcon from "@ingka/ssr-icon";
import { useProjectContext } from "../../contexts/ProjectContext";
import Modal from "react-bootstrap/Modal";

import chevronDown from "@ingka/ssr-icon/paths/chevron-down";
import chevronUp from "@ingka/ssr-icon/paths/chevron-up";

function ProjectChanges(props) {
  const { fetchProjectChanges } = useProjectContext()
  const {projectChanges, setProjectChanges}=useProjectContext()
  const [showAll, setShowAll] = useState(false)

  const [showDetailsArr, setShowDetailsArr]=useState([])
  
  useEffect(async () => {
    await fetchProjectChanges(props.changesId);
    
  }, []);

  function closeModal() {
    setShowAll(false)
    setShowDetailsArr([])
  }

  const modalDetails = (key) => {
    let changesInfo = projectChanges[key];
return(
    
   Object.keys(changesInfo).map((personName) => {
      
     let changesDetails = changesInfo[personName]
   
     return (
       <>
         {Object.keys(changesDetails).map((change) => {
           return (
             <>
               <p>
                 {change
                   .split(/(?=[A-Z])/)
                   .join(" ")
                   .toLowerCase()}
               </p>
               <p style={{justifySelf:"center"}}>{changesDetails[change] }</p>
               <p>{personName}</p>
             </>
           );
         })}
       </>
     );
        

     
      
    }))
  }
  
  function changeDetailsShow(key) {
    
    let arrCopy = Object.assign(showDetailsArr, {})
    console.log(arrCopy)
    if (arrCopy.includes(key)) {
      arrCopy.splice(arrCopy.indexOf(key), 1)
      
    }
    else {
      arrCopy.push(key)
      
    }
    setShowDetailsArr([...arrCopy]);
  }
  
  const sortChangesByDate = (changes) => {
    let d1 = new Date()
    let d2 = new Date();
    let newArr=Object.entries(changes).sort(function (a, b) {
      d1.setHours(a[1].split(":")[0])
      d1.setMinutes(a[1].split(":")[1]);
      d2.setHours(b[1].split(":")[0])
      d2.setMinutes(b[1].split(":")[1]);
      
      if (d1 > d2) {
        
        return -1
      }
      if (d1 < d2) {
       
        return 1
      }
      return 0
    })

    return newArr

 
    
  }



  const changesList = () => {
    return (
     
      
    Object.keys(projectChanges).map((key, index) => {
      let property = projectChanges[key]
      return (
        <>
          {index < 3 && projectChanges[key] && (
            <div className="changesItem" style={styles.changesItem}>
              <h6>{key}</h6>

              {Object.entries(property).map(([key1, value], index1) => (
                <>
                  {index1 < 3 && (
                    <div className="details" style={styles.details}>
                      {/* {Object.keys(property[key1]).map((keyName) => (
                        <>
                          <p>
                            {keyName
                              .split(/(?=[A-Z])/)
                              .join(" ")
                              .toLowerCase()}
                          </p>

                          <p style={{ justifySelf: "end" }}>{key1}</p>
                        </>
                      ))} */}

                      {sortChangesByDate(property[key1]).map((item) => (
                        <>
                          
                          <p>
                            {item[0]
                              .split(/(?=[A-Z])/)
                              .join(" ")
                              .toLowerCase()}
                          </p>
                          <p style={{ justifySelf: "end" }}>{key1}</p>
                        </>
                      ))}
                    </div>
                  )}
                </>
              ))}
            </div>
          )}
        </>
      );
    })
      
      
    )
  }
  return (
    <div className="changesWrapper" style={styles.changesWrapper}>
      {projectChanges ? (
        <>
          <div className="header" style={styles.header}>
            <h5>Changes</h5>
            <SSRIcon paths={clock} style={{ color: "white" }} />
          </div>
          <div className="mainContent" style={styles.mainContent}>
            {changesList()}
            <p style={{ cursor: "pointer" }} onClick={(e) => setShowAll(true)}>
              Show all changes
            </p>
          </div>

          <Modal
            show={showAll}
            onHide={(e) => {
              closeModal();
            }}
            dialogClassName="my-modal-changes"
            contentClassName="modal-height-changes"
          >
            <Modal.Header closeButton>
              <Modal.Title>Project changes</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="bodyWrapper" style={styles.bodyWrapper}>
                {Object.keys(projectChanges).map((key) => (
                  <>
                    <div
                      className="changesDetailItem"
                      style={styles.changesDetailItem}
                    >
                      <h6>
                        {key}{" "}
                        {showDetailsArr.includes(key) && (
                          <>
                            <SSRIcon
                              paths={chevronUp}
                              onClick={(e) => changeDetailsShow(key)}
                              style={{ cursor: "pointer" }}
                            />
                            <div
                              className="detials"
                              style={styles.detailsInModal}
                            >
                              <p style={styles.pModal}>Field name</p>
                              <p style={styles.pModal}>Last time changed</p>
                              <p style={styles.pModal}>Who changed</p>
                              {modalDetails(key)}
                            </div>
                          </>
                        )}
                        {!showDetailsArr.includes(key) && (
                          <SSRIcon
                            paths={chevronDown}
                            onClick={(e) => changeDetailsShow(key)}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </h6>
                    </div>
                  </>
                ))}
              </div>
            </Modal.Body>

            <Modal.Footer>
              <div className="footerWrapper" style={styles.footerWrapper}></div>
            </Modal.Footer>
          </Modal>
        </>
      ) : null}
    </div>
  );
}

export default ProjectChanges;

const styles = {
  changesWrapper: {
    color: "white",
    marginTop: "10vh",
    width: "92%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  mainContent: {
    display: "grid",
    gridTemplateRows: "auto",
  },
  changesItem: {
    display: "flex",

    marginTop: "3vh",
    flexDirection: "column",
    alignItems: "start",
  },
  details: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    justifyItems: "start",
    justifyContent: "space-between",
    width: "100%",
    columnGap: "2vw",
    marginTop: "3vh",
  },
  changesDetailItem: {
    border: "1px solid grey",
    width: "70%",
    padding: "1vw",
    borderRadius: "5px",
    textAlign: "center",
  },
  bodyWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",

    alignItems: "center",
    gap: "3vh",
  },
  detailsInModal: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    marginLeft: "4vw",
    columnGap: "2vw",
    justifyItems: "start",
    marginTop: "3vh",
    maxHeight: "20vh",
    overflowY: "scroll",
  },
  pModal: {
    color: "grey",
    fontWeight: "normal",
    padding:"2vh 0 2vh 0"
  }
};
