import React, { createContext, useContext, useState, useEffect } from "react";


function SuccessPopover(props) {

  
  return (
    <>
      {props.show && (  <div className="editedSuccessfully" style={styles.success}>
        <p>Changes have been saved</p>
      </div>)}
    
    </>
  );
}

export default SuccessPopover;

const styles = {
  success: {
    height: "10vh",
    position: "fixed",
    bottom: "50px",
    backgroundColor: "rgb(166, 209, 168)",
    color: "white",
    padding: "2vh 2vh 0 2vh",
    fontWeight: "bold",
    right: "5%",
  },
};
