import React, { useState, useEffect, useContext } from "react";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import { useProjectContext } from "../contexts/ProjectContext";
import { useUserContext } from "../contexts/UserContext";
import SSRIcon from "@ingka/ssr-icon";
import starFilled from "@ingka/ssr-icon/paths/star-filled";
import star from "@ingka/ssr-icon/paths/star";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Loading, { LoadingBall } from "@ingka/loading";
import Pill from "@ingka/pill";
import FilterSidebar from "../components/FilterSidebar";
import { useUnitContext } from "../contexts/UnitContext";
import filters from "@ingka/ssr-icon/paths/filters";
import Pagination from "react-bootstrap/Pagination";

function UnitPage() {


  const { requestAccessToken } = useUserContext();
  const {
    getAllUnits,
    projectName,
    establishmentType,
    grossUnitArea,
    unitsSizePerPage,
    setUnitsSizePerPage,
    unitsMaxPage,
    unitsCurrentPage,
    setUnitsCurrentPage,
  } = useUnitContext();
    const [projects, setProjects] = useState({});
    const [units, setUnits] = useState({});
    const [favoriteIds, setFavoriteIds] = useState([]);
    const [loading, setLoading]=useState(true)
    const [error, setError] = useState(null)
    let navigate = useNavigate();
  const [getResult, setGetResult] = useState(null);
  const [showModal, setShowModal]=useState(false)

    const fortmatResponse = (res) => {
      return JSON.stringify(res, null, 2);
    }


    const api = process.env.REACT_APP_API;

  function handlePrev() {
    if (unitsCurrentPage - 1 !== 0) {
      /*   if (country !== "all") {
          setLastIndex(currentProjects[currentProjects.length - 1].project_ID-5);
        } */
      setUnitsCurrentPage(unitsCurrentPage - 1);
    }
  }

  function handleNext() {
    if (!(unitsCurrentPage + 1 > unitsMaxPage)) {
      /*  if (country !== "all") {
        setLastIndex(currentProjects[currentProjects.length-1].project_ID)
      } */
      setUnitsCurrentPage(unitsCurrentPage + 1);
    }
  }
   

    useEffect(async () => {
      const unitsRes = await getAllUnits()
      setUnits(unitsRes)
    }, [projectName, establishmentType, grossUnitArea, unitsCurrentPage, unitsSizePerPage])

    const tabs = [
        <div className="tab-container">
         
        <Tab key="tab-1" text={"All"} tabPanelId="tab_1" />
        <Tab key="tab-2" text={"Favorite"} active tabPanelId="tab_2" />
        </div>
      ];

      const tabPanels = [
        <TabPanel key="tab-1" tabPanelId="tab_1" title="">
          <div class="table table--inset">
        
            {units.length > 0 ? (
              <table className="projectTable" style={styles.projectTable}>
                <thead class="">
                  <tr>
                    <th></th>
                    <th>ID</th>
                    <th>Project Name</th>
                    <th>Unit Type</th>
                    <th>Establishment Type</th>
                    <th>Gross Unit Area</th>
                  </tr>
                </thead>
    
                <tbody
                  class="table-body--striped tableBody"
                  style={styles.tableBody}
                >
                  {units.map((item, index) => (
                    <>
                      <tr>
                        <td>
                          {" "}
                          
                      
                        </td>
                      
                        <td>{item.id}</td>
                        <td>{item.projectName}</td>
                      <td>{item.unitType}</td>
                        <td>{item.establishmentType}</td>
                        <td>{item.grossUnitArea}</td>
    
                        <td>
                          <button
                            aria-disabled="false"
                            type="button"
                            class="btn btn--small btn--icon-primary projectDetailsPill"
                            onClick={e=> navigate("/units/"+item.id)}
                          >
                            <span class="btn__inner">
                              <svg
                                focusable="false"
                                viewBox="0 0 24 24"
                                class="svg-icon btn__icon"
                                aria-hidden="true"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M19.2937 12.7074L20.0008 12.0003L19.2938 11.2932L12.0008 3.99927L10.5865 5.41339L16.1727 11.0003H4V13.0003H16.1723L10.5855 18.5868L11.9996 20.0011L19.2937 12.7074Z"
                                ></path>
                              </svg>
                              <span class="btn__label">Label</span>
                            </span>
                          </button>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            ) : (
               
              <Loading
                className={`custom-loading--custom-class`}
                text="Loading..."
                labelTransitions
              >
                <ClipLoader loading={loading} size={80} />
              </Loading>
            )}
            {units.length === 0 ? (
              <>
                <p className="noProjects" style={styles.noProjects}>
                  No units found
                </p>
              </>
            ) : (
              <></>
            )}
          </div>
        </TabPanel>
    
    
      ];

      
      return (
        <>
          <div style={{ marginTop: "10vw", padding: "5vh 5vw 2vh 5vw" }}>
            <h3 style={{ textAlign: "left" }}>Units</h3>
          </div>
          <div className="browse" style={styles.browse}>
            <Pill
              type="emphasised"
              selected={false}
              iconPosition="trailing"
              ssrIcon={filters}
              label="Filters"
              onClick={(e) => setShowModal(true)}
            />
            <div className="textContainer" style={styles.textContainer}>
              <p style={{ marginTop: "0.7vw" }}>Units Shown : </p>

              <input
                style={styles.input}
                type="number"
                value={unitsSizePerPage}
                onChange={(e) => setUnitsSizePerPage(e.target.value)}
              />
            </div>
          </div>
          <FilterSidebar
            openModal={showModal}
            closeModal={(e) => setShowModal(false)}
            entity="unit"
          />
          <div className="tabsWrapper" style={styles.tabsWrapper}>
            <Tabs tabs={tabs} tabPanels={tabPanels} defaultActiveTab="tab_1" />
          </div>
          <div className="pagination" style={styles.pages}>
            <Pagination>
              <Pagination.First onClick={(e) => setUnitsCurrentPage(1)} />
              <Pagination.Prev onClick={(e) => handlePrev()} />
              <div className="wrapper" style={styles.wrapper}>
                <Pagination.Item>
                  {unitsCurrentPage} out of {unitsMaxPage}
                </Pagination.Item>
              </div>

              <Pagination.Next onClick={(e) => handleNext()} />
              <Pagination.Last
                onClick={(e) => setUnitsCurrentPage(unitsMaxPage)}
              />
            </Pagination>
          </div>
        </>
      );



}

export default UnitPage;

const styles = {
  projectTable: {
    width: "90vw",
  },
  star: {
    cursor: "pointer",
  },
  noProjects: {
    padding: "2vh",
  },
  tabsWrapper: {
    padding: "2vh 5vw 2vh 5vw",
  },
  browse: {
    width: "50%",
    marginLeft: "45vw",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "1vw",
    justifyItems: "end",
    paddingRight: "5vw",
  },
  textContainer: {
    height: "3.5rem",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    borderRadius: "64px",
    padding: "1vh 1vw 1vh 1vw",
    fontWeight: "bold",
    fontSize: "0.875rem",

    verticalAlign: "top",
    color: "#111111",
    outline: "0",
    border: "1px solid #f5f5f5",
    position: "relative",
    lineHeight: "1.4285714286",
    width: "15vw",

    display: "flex",
    justifyContent: "center",
  },
  input: {
    border: "0.4px solid #e4e2e2",

    marginLeft: "1vw",

    width: "20%",
    borderRadius: "5px",
  },
  pages: {
    marginLeft: "4vw",
  },
};

