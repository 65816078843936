import React, { useState, useEffect, useContext } from "react";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import { useProjectContext } from "../contexts/ProjectContext";
import { useUserContext } from "../contexts/UserContext";
import SSRIcon from "@ingka/ssr-icon";
import starFilled from "@ingka/ssr-icon/paths/star-filled";
import star from "@ingka/ssr-icon/paths/star";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Loading, { LoadingBall } from "@ingka/loading";
import Button from "@ingka/button";
import { Select, Option, OptGroup } from "@ingka/select";
import Pages from "./Pages";
import EditInBatchModal from "./HomePage/BatchEditing/EditInBatchModal";
import { useColumnContext } from "../contexts/ColumnContext";
import Checkbox from "@ingka/checkbox";
import SuccessPopover from "../components/SuccessPopover"
import { usePostProjectContext } from "../contexts/PostProjectContext";

function ToggleList(props) {
  const {
    fetchAllProjects,
    // fetchAllSites,
    addFavorite,
    fetchFavoriteProjectIds,
    deleteFavorite,
    status,
    country,
    filterName,
    city,
    currentPage,
    mainProject,
    sizePerPage,
    projects,
    setProjects,
    batchProjects,
    setBatchProjects,
  } = useProjectContext();
  const {editingProjects, setEditingProjects}=usePostProjectContext()

  const { userColumns, setUserColumns, columns, fetchUserColumns } =
    useColumnContext();
  const [showSuccess, setShowSuccess]=useState(false)
  

  const { requestAccessToken } = useUserContext();

  const [sites, setSites] = useState({});
  const [favoriteIds, setFavoriteIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  let navigate = useNavigate();
  const [getResult, setGetResult] = useState(null);
  const [showModal, setShowModal] = useState(false);

  async function addNewFavorite(e, projectId) {
    e.stopPropagation();
    await addFavorite(projectId);
    await updateProjects();
  }

  async function deleteFavoriteProject(e, projectId) {
    e.stopPropagation();
    await deleteFavorite(projectId);
    await updateProjects();
  }

  async function addProjectInBatch(project) {
    let copy = Object.assign(batchProjects, {});
    if (batchProjects.includes(project)) {
      copy.splice(copy.indexOf(project),1)
    }
    else {
      copy.push(project)
    }
    await setBatchProjects([...copy])
    console.log(copy)
  }

  async function updateProjects() {
    await fetchAllProjects();
    let favoriteIdsRes = await fetchFavoriteProjectIds();
    setFavoriteIds(favoriteIdsRes.favorites);

    if (projects.length === 0) {
      setLoading(false);
    }
  }

  async function showSuccessPopup() {
     await setBatchProjects([]);
    setShowModal(false);
    props.switchValue()
    await updateProjects()
    setShowSuccess(true)
     setTimeout(function () {
       setShowSuccess(false);
     }, 5000);

    
  }

  useEffect(async () => {
    await fetchUserColumns();
    await updateProjects();
  }, [
    status,
    country,
    mainProject,
    filterName,
    city,
    currentPage,
    sizePerPage,
    editingProjects,
  ]);

  const tabs = [
    <div className="tab-container">
      <Tab key="tab-1" text={"All"} tabPanelId="tab_1" />
      <Tab key="tab-2" text={"Favorite"} active tabPanelId="tab_2" />
    </div>,
  ];

  const tabPanels = [
    <>
      {userColumns && (
        <>
          <TabPanel key="tab-1" tabPanelId="tab_1" title="">
            <div class="table table--inset">
              {projects && projects.length > 0 && userColumns ? (
                <table className="projectTable" style={styles.projectTable}>
                  <thead class="">
                    <tr>
                      {props.editBatch && <th></th>}
                      <th></th>
                      {userColumns && (
                        <>
                          {Object.keys(columns).map((key) => (
                            <>
                              {userColumns[key] && (
                                <th>
                                  {key
                                    .split(/(?=[A-Z])/)
                                    .join(" ")
                                    .toLowerCase()}
                                </th>
                              )}
                            </>
                          ))}
                        </>
                      )}
                    </tr>
                  </thead>

                  <tbody
                    class="table-body--striped tableBody"
                    style={styles.tableBody}
                  >
                    {projects.map((item, index) => (
                      <>
                        <tr>
                          {props.editBatch && (
                            <td>
                              <Checkbox
                                onChange={(e) =>
                                  addProjectInBatch(item)
                                }
                               
                              />
                            </td>
                          )}
                          <td>
                            {" "}
                            {favoriteIds.includes(item.id.toString()) && (
                              <>
                                <SSRIcon
                                  style={styles.star}
                                  paths={starFilled}
                                  onClick={(e) =>
                                    deleteFavoriteProject(e, item.id)
                                  }
                                />
                              </>
                            )}
                            {!favoriteIds.includes(item.id.toString()) && (
                              <>
                                <SSRIcon
                                  style={styles.star}
                                  paths={star}
                                  onClick={(e) => addNewFavorite(e, item.id)}
                                />
                              </>
                            )}
                          </td>
                          {Object.keys(columns).map((key) => (
                            <>
                              {userColumns[key] && (
                                <td>
                                  {key === "id"
                                    ? item[key]
                                    : item.site[key].toString()}
                                </td>
                              )}
                            </>
                          ))}

                          <td>
                            <button
                              aria-disabled="false"
                              type="button"
                              class="btn btn--small btn--icon-primary projectDetailsPill"
                              onClick={(e) => navigate("/project/" + item.id)}
                            >
                              <span class="btn__inner">
                                <svg
                                  focusable="false"
                                  viewBox="0 0 24 24"
                                  class="svg-icon btn__icon"
                                  aria-hidden="true"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M19.2937 12.7074L20.0008 12.0003L19.2938 11.2932L12.0008 3.99927L10.5865 5.41339L16.1727 11.0003H4V13.0003H16.1723L10.5855 18.5868L11.9996 20.0011L19.2937 12.7074Z"
                                  ></path>
                                </svg>
                                <span class="btn__label">Label</span>
                              </span>
                            </button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              ) : (
                <Loading
                  className={`custom-loading--custom-class`}
                  text="Loading..."
                  labelTransitions
                >
                  <ClipLoader loading={loading} size={80} />
                </Loading>
              )}
              {projects && projects.length === 0 ? (
                <>
                  <p className="noProjects" style={styles.noProjects}>
                    No projects found
                  </p>
                </>
              ) : (
                <></>
              )}
            </div>
          </TabPanel>

          <TabPanel key="tab-2" tabPanelId="tab_2">
            <div class="table table--inset">
              <table className="projectTable" style={styles.projectTable}>
                <thead class="">
                  <tr>
                    {props.editBatch && <th></th>}
                    <th></th>

                    {userColumns && (
                      <>
                        {Object.keys(columns).map((key) => (
                          <>
                            {userColumns[key] && (
                              <th>
                                {key
                                  .split(/(?=[A-Z])/)
                                  .join(" ")
                                  .toLowerCase()}
                              </th>
                            )}
                          </>
                        ))}
                      </>
                    )}
                  </tr>
                </thead>

                <tbody
                  class="table-body--striped tableBody"
                  style={styles.tableBody}
                >
                  {projects && projects.length > 0 && userColumns ? (
                    projects.map((item, index) => (
                      <>
                        {favoriteIds.includes(item.id.toString()) ? (
                          <tr>
                            {props.editBatch && (
                              <td>
                                <Checkbox
                                  onChange={(e) =>
                                    addProjectInBatch(item)
                                  }
                                  
                                />
                              </td>
                            )}
                            <td>
                              <SSRIcon
                                style={styles.star}
                                paths={starFilled}
                                onClick={(e) =>
                                  deleteFavoriteProject(e, item.id)
                                }
                              />
                            </td>

                            {Object.keys(columns).map((key) => (
                              <>
                                {userColumns[key] && (
                                  <td>
                                    {key === "id"
                                      ? item[key]
                                      : item.site[key].toString()}
                                  </td>
                                )}
                              </>
                            ))}

                            <td>
                              <button
                                aria-disabled="false"
                                type="button"
                                class="btn btn--small btn--icon-primary projectDetailsPill"
                                onClick={(e) => navigate("/project/" + item.id)}
                              >
                                <span class="btn__inner">
                                  <svg
                                    focusable="false"
                                    viewBox="0 0 24 24"
                                    class="svg-icon btn__icon"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M19.2937 12.7074L20.0008 12.0003L19.2938 11.2932L12.0008 3.99927L10.5865 5.41339L16.1727 11.0003H4V13.0003H16.1723L10.5855 18.5868L11.9996 20.0011L19.2937 12.7074Z"
                                    ></path>
                                  </svg>
                                  <span class="btn__label">Label</span>
                                </span>
                              </button>
                            </td>
                          </tr>
                        ) : null}
                      </>
                    ))
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </TabPanel>
        </>
      )}
    </>,
  ];

  return (
    <>
      <Tabs tabs={tabs} tabPanels={tabPanels} defaultActiveTab="tab_1" />
      <div className="pagesbtnWrapper" style={styles.pagesbtnWrapper}>
        <Pages />
        {props.editBatch && batchProjects.length > 0 && (
          <>
            <Button
              type="primary"
              text="Start editing →"
              className="primaryBtn"
              small="true"
              onClick={(e) => setShowModal(true)}
            />
          </>
        )}
        <EditInBatchModal
          show={showModal}
          projects={batchProjects}
          emitData={(e) => setShowModal(false)}
          success={(e) => showSuccessPopup()}
        />
        <SuccessPopover show={showSuccess} />
      </div>
    </>
  );
}

export default ToggleList;

const styles = {
  projectTable: {
    width: "90vw",
  },
  star: {
    cursor: "pointer",
  },
  noProjects: {
    padding: "2vh",
  },
  pagesbtnWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
};
