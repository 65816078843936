import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";


function handleLogin(instance) {
  instance.loginRedirect(loginRequest).catch((e) => {
    console.error(e);
  });
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
  const { instance } = useMsal();

  return (
    <div className="btnWrapper" style={styles.btnWrapper}>
      <button
        type="button"
        class="jumbo-btn jumbo-btn--emphasised signIn"
        onClick={() => handleLogin(instance)}
        style={styles.btn}
      >
        <span class="jumbo-btn__inner">
          <span class="jumbo-btn__label">Log in</span>
          <span class="jumbo-btn__icon">
            <svg
              focusable="false"
              viewBox="0 0 24 24"
              class="svg-icon"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.2937 12.7074L20.0008 12.0003L19.2938 11.2932L12.0008 3.99927L10.5865 5.41339L16.1727 11.0003H4V13.0003H16.1723L10.5855 18.5868L11.9996 20.0011L19.2937 12.7074Z"
              ></path>
            </svg>
          </span>
        </span>
      
      </button>
    </div>
  );
};


const styles = {
  btn: {
    width:"20vw"
  },
  btnWrapper: {
    height: "84vh",
  
    display:"flex",
    flexDirection: "row",
   justifyContent: "center",
   alignItems: "center"
  }
}