import React, { useEffect, useState, useContext } from "react";
import SSRIcon from "@ingka/ssr-icon";
import chevronDown from "@ingka/ssr-icon/paths/chevron-down";
import chevronUp from "@ingka/ssr-icon/paths/chevron-up";
import pencil from "@ingka/ssr-icon/paths/pencil";
import circle from "@ingka/ssr-icon/paths/plus-circle";
import { useSiteContext } from "../../contexts/SiteContext";
import { useUnitContext } from "../../contexts/UnitContext";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import UnitForm from "../CreateNewProject/Units/UnitForm"
import Button from "@ingka/button";

function EditConnections(props) {
  const { siteToPost, siteId, deleteUnitFromSite } = useSiteContext();
  const {
    units,
    setUnits,
    unitIdsToDelete,
    setUnitIdsToDelete,
    setUnitSize,
  
  } = useUnitContext();
  const [showUnits, setShowUnits] = useState(false)
  const [showAddUnits, setShowAddUnits]=useState(false)
  useEffect(() => {
    setShowUnits(false)
  }, [])

  async function deleteUnit(unitId, unit) {
 
    if (units.length > 1) {
      /* let res = await deleteUnitFromSite(unitId, siteId)
      if (res.status === 200) {
       props.refresh()
      } */
      let newArr = units.filter((unit) => unit.id !== unitId)
      await setUnits(newArr)
     
      unitIdsToDelete.push(unit)
     
    }
  
  }

  function handleShowUnits() {
    setShowAddUnits(true)
  }
  


  function popover(unitId, unit) {
    return (
      <Popover id="popover-basic">
        <div className="popover" style={styles.popover}>
          {units.length>1 && (<p className="deleteUnit" onClick={e=> deleteUnit(unitId, unit)}>Disconnect unit</p>)}
          
          <p className="editUnit">Edit unit</p>
        </div>
      </Popover>
    );
  }

  return (
    <div className="connections" style={styles.connections}>
      {!showAddUnits && (
        <>
          <div
            className="siteWrapper"
            style={styles.siteWrapper}
            onClick={(e) => setShowUnits(!showUnits)}
          >
            {siteToPost && units && siteId && (
              <>
                {showUnits ? (
                  <SSRIcon paths={chevronUp} />
                ) : (
                  <SSRIcon paths={chevronDown} />
                )}
                <p>Site #{siteId}</p>
                <p style={styles.grey}>+ {units.length} unit(s)</p>
              </>
            )}
          </div>
          {showUnits && (
            <div className="unitsList" style={styles.unitsList}>
              <div style={styles.text}>
                <h7 style={styles.connection}>|</h7>
                <h7 style={styles.connection}>|</h7>
                <h7 style={styles.connection}>
                  |____{" "}
                  <span style={styles.span}>
                    Add / edit unit(s){" "}
                    <SSRIcon
                      paths={circle}
                      onClick={(e) => handleShowUnits()}
                    />
                  </span>
                </h7>
              </div>
              {units.map((unit, index) => (
                <div className="unitWrapper" style={styles.unitWrapper}>
                  <div style={styles.text}>
                    <h7 style={styles.connection}>|</h7>
                    <h7 style={styles.connection}>|</h7>
                    <h7 style={styles.connection}>|____</h7>
                  </div>
                  <div className="unitItem" style={styles.unitItem}>
                    <p>Unit #{unit.id}</p>
                    <p>{unit.projectName}</p>
                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      overlay={popover(unit.id, unit)}
                    >
                      <button>
                        {" "}
                        <SSRIcon paths={pencil} />
                      </button>
                    </OverlayTrigger>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}

      {showAddUnits && (
        <div style={styles.unitsWrapper}>
          <UnitForm />
          <Button
            type="secondary"
            onClick={(e) => setShowAddUnits(false)}
            text="← Back"
            small="true"
          />
        </div>
      )}
    </div>
  );
}

export default EditConnections;

const styles = {
  siteWrapper: {
    width: "80%",
    border: "solid grey 1px",
    borderRadius: "10px",
    display: "grid",
    gridTemplateColumns: "20% 40% 40%",
    padding: "1vw",
    cursor: "pointer",
    justifyItems: "base-line",
    alignItems: "start",
  },
  connections: {
    display: "grid",

    flexDirection: "column",

    justifyItems: "center",
  },
  text: {
    display: "flex",
    flexDirection: "column",
  },
  connection: {
    fontSize: "2rem",
    marginTop: "-3vh",
  },
  unitWrapper: {
    marginLeft: "2vw",
    marginTop: "0.5vw",
    display: "flex",
  },
  unitsList: {
    height: "30vh",
    overflowY: "scroll",
    width: "80%",
    justifySelf: "center",
  },
  unitItem: {
    width: "50%",
    height: "80%",
    border: "solid grey 1px",
    borderRadius: "10px",
    display: "grid",
    gridTemplateColumns: "20% 40% 40%",
    alignSelf: "flex-end",
    padding: "1vw 2vw 0 2vw",

    justifyItems: "base-line",
    alignItems: "start",
  },
  grey: {
    color: "grey",
  },
  span: {
    fontSize: "0.5em",
  },
  popover: {
    display: "grid",
    gridTemplateRows: "repeat(2, 1fr)",

    justifyItems: "stretch",
    minWidth:"12vw"
  },
  unitsWrapper: {
    width:"100%"
  }
};
