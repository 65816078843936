import React, { createContext, useContext, useState, useEffect } from "react";
import { useSiteContext } from "../contexts/SiteContext";
import {
  BrowserRouter as Router,
  useParams,
  useNavigate,
} from "react-router-dom";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import Table from "@ingka/table";
import { TableHeader, TableBody } from "@ingka/table";
import Button from "@ingka/button";

function SiteDetailsPage() {
  const { fetchOneSite } = useSiteContext()
  const [site, setSite]=useState({})
  const { siteId } = useParams();
  let navigate = useNavigate();

  const tabs = [
    <div className="tab-container">
      <Tab key="tab-1" text={"Overview"} tabPanelId="tab_1" />
      <Tab key="tab-2" text={"Unit(s)"} active tabPanelId="tab_2" />
    </div>,
  ];
  
  const tabPanels = [
    <TabPanel key="tab-1" tabPanelId="tab_1" title="">
      <div className="overviewWrapper" style={styles.ovreviewWrapper}>
        <div className="left" style={styles.detailsColumn}>
          <p style={styles.weight}> State/province</p>
          <p>{site.state}</p>
          <p style={styles.weight}>Address</p>
          <p>{site.address}</p>
          <p style={styles.weight}>Location name</p>
          <p>{site.locationName}</p>
          <p style={styles.weight}>Site type</p>
          <p>{site.siteType}</p>
          <p style={styles.weight}>Real estate cluster responsible</p>
          <p>{site.realEstateClusterResponsible}</p>
          <p style={styles.weight}>Country project responsible</p>
          <p>{site.countryProjectResponsible}</p>
          <p style={styles.weight}>Main project</p>
          <p>{site.mainProject}</p>
          <p style={styles.weight}>Site progress</p>
          <p>{site.siteProgress}</p>
        </div>

        <div className="right" style={styles.detailsColumn}>
          <p style={styles.weight}>Confidential</p>
          <p>{site.confidential ? "Yes" : "No"}</p>
          <p style={styles.weight}>Part of a city plan</p>
          <p>{site.partOfACityPlan ? "Yes" : "No"}</p>
          <p style={styles.weight}>Part of an approved MPP</p>
          <p>{site.partOfAnApprovedMPP ? "Yes" : "No"}</p>
          <p style={styles.weight}>Site accessible date</p>
          <p>{site.siteAccessibleDate}</p>
          <p style={styles.weight}>Transaction type</p>
          <p>{site.transactionType}</p>
          <p style={styles.weight}>Land size (m2)</p>
          <p>{site.landSize}</p>
          <p style={styles.weight}>Gross building area (m2)</p>
          <p>{site.grossBuildingArea}</p>
        </div>
      </div>
    </TabPanel>,
    <TabPanel key="tab-2" tabPanelId="tab_2" title="">
      <Table style={styles.table}>
        <TableHeader sticky={true}>
          <tr>
            <th></th>
            <th>ID</th>
            <th>Unit type</th>
            <th>Establishmnet type</th>
            <th>Project name</th>
            <th>Part of programme</th>
            <th></th>
          </tr>
        </TableHeader>
        <TableBody striped="true">
          {site.units && (
            <>
              {site.units.map((unit) => (
                <tr>
                  <td></td>
                  <td>{unit.id}</td>
                  <td>{unit.unitType}</td>
                  <td>{unit.establishmentType}</td>
                  <td>{unit.projectName}</td>
                  <td>{unit.partOfProgramme.toString()}</td>
                  <td>
                    <button
                      aria-disabled="false"
                      type="button"
                      class="btn btn--small btn--icon-primary projectDetailsPill"
                      onClick={(e) => navigate("/units/" + unit.id)}
                    >
                      <span class="btn__inner">
                        <svg
                          focusable="false"
                          viewBox="0 0 24 24"
                          class="svg-icon btn__icon"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M19.2937 12.7074L20.0008 12.0003L19.2938 11.2932L12.0008 3.99927L10.5865 5.41339L16.1727 11.0003H4V13.0003H16.1723L10.5855 18.5868L11.9996 20.0011L19.2937 12.7074Z"
                          ></path>
                        </svg>
                        <span class="btn__label">Label</span>
                      </span>
                    </button>
                  </td>
                </tr>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </TabPanel>,
  ];
  useEffect(async () => {
    let siteRes=await fetchOneSite(siteId)
    setSite(siteRes);
    

  }, [siteId]);


  return (
    <div className="siteWrapper" style={styles.siteWrapper}>
      {site && (
        <>
          <h2>Site #({siteId})</h2>
          <h6>
            {site.country}, {site.city}
          </h6>
          <div className="detailsWrapper">
            <Tabs tabs={tabs} tabPanels={tabPanels} defaultActiveTab="tab_1" />
          </div>
        </>
      )}
    </div>
  );
}

export default SiteDetailsPage;

const styles = {
  siteWrapper: {
    width: "100%",

    padding: "20vh 7vh 7vh 7vh",
  },
  ovreviewWrapper: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "1vw",
    justifyItems: "center",
  },
  detailsColumn: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",

    justifyItems: "start",
    columnGap: "8vw",
    rowGap: "2vh",
  },
  weight: {
    fontWeight: "bold",
  },
 
  table: {
    width: "100%",
    padding: "1vh",
    
  },
};
